import { getErrorMessage } from '@/services/utils/Utils.js';

export const namespaced = true;

export const state = {
  notifications: [],
};

let nextId = 1;

export const getters = {
  notifications: ({ notifications }) => notifications,
};

export const mutations = {
  ADD(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    });
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter((notification) => notification.id !== notificationToRemove.id);
  },
};
export const actions = {
  addNotification({ commit }, { type, error, message }) {
    if (type === 'error') {
      commit('ADD', { type, message: message || getErrorMessage(error) });
    } else {
      commit('ADD', { type, message });
    }
  },
  removeNotification({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove);
  },
};
