import * as FirestoreService from '@/services/firebase/FirestoreService';
import LeaveTimeEntry from '@/core/entities/LeaveTimeEntry';
import LeaveTimeEntriesRepository from '@/core/features/leave-time-entries/interfaces/LeaveTimeEntriesRepository';

export default class LeaveTimeEntriesRepositoryFirestoreImpl implements LeaveTimeEntriesRepository {
  private collectionName = 'vacationTimeEntries';
  public async createLeaveTimeEntry(leaveTimeEntry: LeaveTimeEntry): Promise<LeaveTimeEntry> {
    const leaveTimeEntryId = await FirestoreService.saveRecord({
      collectionName: this.collectionName,
      data: leaveTimeEntry,
    } as any);
    return new LeaveTimeEntry({ ...leaveTimeEntry, id: leaveTimeEntryId });
  }

  public async deleteLeaveTimeEntry({ id }: { id: string }): Promise<void> {
    await FirestoreService.deleteRecord({
      collectionName: this.collectionName,
      id,
    } as any);
  }
}
