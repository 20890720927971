import { querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  accountingRecords: [],
  subscription: null,
};

export const getters = {
  accountingRecords: ({ accountingRecords }) => accountingRecords,
  accountingRecord: (state) => (id) => state.accountingRecords.find((accountingRecord) => accountingRecord.id === id),
};

export const mutations = {
  SET_ACCOUNTING_RECORDS(state, accountingRecords) {
    state.accountingRecords = accountingRecords;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchAccountingRecords({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const firestore = firebase.firestore();
    const collection = firestore.collection('accounting');

    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_ACCOUNTING_RECORDS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveAccountingRecord(context, accountingOptions) {
    return saveRecord(accountingOptions).then((id) => id);
  },
  fetchAccountingRecord({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.accountingRecord(id)) {
        resolve(getters.accountingRecord(id));
        return;
      }

      const accountingDocument = firestoreDocument('accounting')(id);
      accountingDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
