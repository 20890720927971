//
import { firestoreDocument } from '@/services/utils/Utils.js';
import { saveRecord, getRecords } from '@/services/firebase/FirestoreService.js';

export const namespaced = true;

export const state = {
  proposalRequests: [],
  subscription: null,
};

export const getters = {
  proposalRequests: ({ proposalRequests }) => proposalRequests,
  proposalRequest: (state) => (id) => state.proposalRequests.find((proposalRequest) => proposalRequest.id === id),
};

export const mutations = {
  SET_PROPOSAL_REQUEST(state, proposalRequests) {
    state.proposalRequests = proposalRequests;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchProposalRequests({ commit }, config) {
    return getRecords({
      ...config,
      collectionName: 'proposalRequests',
    }).then((records) => {
      commit('SET_PROPOSAL_REQUEST', records);
    });
  },
  saveProposalRequest(context, proposalRequestOptions) {
    return saveRecord(proposalRequestOptions).then((id) => id);
  },
  fetchProposalRequest({ getters }, id) {
    const proposalRequestDocument = firestoreDocument('proposalRequests')(id);
    return proposalRequestDocument
      .get()
      .then((querySnapshot) => ({
        ...querySnapshot.data(),
        id: querySnapshot.id,
      }))
      .catch((error) => {
        return error;
      });
  },
};
