<template lang="pug">
  v-input.v-custom-input(hide-details="auto" :error-messages="errors" v-on="$listeners" :error-count="errors?.length || 0"
    :class="{'primary--text': isEditing, 'v-input--is-focused': isEditing, 'v-text-field--outlined': outlined}" dense)
    //- it seems that v-input doesn't take the normal slots
    //-template(v-for="field in Object.keys($scopedSlots)" v-slot:[field]="slotProps")
    //-  slot(v-if="field !== 'default'" :name="field" v-bind="slotProps")
    template(#default)
      fieldset.rounded(aria-hidden="true")
        legend(:style="{width: legendWidth + 'px'}")
          span &ZeroWidthSpace;
      v-col.pa-0(cols="12")
        slot(name="default" :activators="{focus: focusListener, blur: blurListener}")
    template(#label)
      span.d-block(:class="{'primary--text': isEditing && !errors.length, 'v-label--active': (isEditing || hasValue)}"
        style="transform-origin: top left;" ref="label")
        |{{label}}
        label.red--text(v-if="required") *
    template(#prepend v-if="prependIcon")
      v-icon {{prependIcon}}
    template(#append)
      slot(name="append")
</template>

<script lang="ts">
export default {
  name: 'BaseCustomInput',
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    isEditing: false,
  }),
  computed: {
    legendWidth: function () {
      return this.label && this.$refs.label && (this.isEditing || this.hasValue)
        ? Math.min(this.$refs.label.scrollWidth * 0.75 + 6, ((this.$el && this.$el.offsetWidth) || Number.POSITIVE_INFINITY) - 24)
        : 0;
      //Math.min(this.$refs.label.scrollWidth * 0.75 + 6, (this.$el as HTMLElement).offsetWidth - 24)
      //0.75 is the scale transition
    },
  },
  methods: {
    focusListener: function () {
      this.isEditing = true;
      this.$emit('focus');
    },
    blurListener: function () {
      this.isEditing = false;
      this.$emit('blur');
    },
  },
};
</script>

<style>
.v-custom-input > .v-input__control > .v-input__slot {
  padding: 0 12px;
  flex-wrap: wrap;
}
</style>
