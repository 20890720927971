import LeaveRequestRepository from '../interfaces/LeaveRequestRepository';
import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import LeaveRequest from '@/core/entities/LeaveRequest';

export default class UpdateLeaveRequest {
  private leaveRequestRepository: LeaveRequestRepository;
  private authenticationGateway: AuthenticationGateway;

  constructor(leaveRequestRepository: LeaveRequestRepository, authenticationGateway: AuthenticationGateway) {
    this.leaveRequestRepository = leaveRequestRepository;
    this.authenticationGateway = authenticationGateway;
  }

  async execute({ id, data }: { id: string; data: any }): Promise<LeaveRequest> {
    if (!id) {
      throw new Error('Leave request ID is required');
    }

    const { uid } = await this.authenticationGateway.getCurrentUser();
    const modifiedDate = new Date().toISOString();
    const updatedSystemFields = { modifiedBy: uid, modifiedDate };

    const leaveRequest = new LeaveRequest({
      ...data,
      ...updatedSystemFields,
    });
    const isInvalid = leaveRequest.validate();

    if (isInvalid) {
      throw new Error('Invalid Leave Request Data', { cause: isInvalid });
    }
    const newLeaveRequest = await this.leaveRequestRepository.updateLeaveRequest({
      id,
      data: leaveRequest,
    });
    return newLeaveRequest;
  }
}
