import $ from 'jquery';
import swal from 'sweetalert';

const CHECK_INTERVAL = 1000 * 60 * 10; // 10 minutes

export function checkForNewVersions() {
  const sourcesRegex = /\/assets\/[^"]+\.(js|css)/gi;
  const cssJsSources = document.documentElement.outerHTML.match(sourcesRegex) || [];

  setInterval(() => {
    checkVersion(cssJsSources);
  }, CHECK_INTERVAL);
}

function showUpdateAlert() {
  swal({
    title: 'A new version of GRID has been detected!',
    text: "You are strongly advised to save your work and reload the page to prevent any inconsistency. If you've already refreshed and still see this message, try using Ctrl + Shift + R (PC) or Cmd + Shift + R (Mac).",
    icon: 'warning',
    buttons: {
      cancel: 'Later',
      confirm: {
        text: 'Reload Page',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
    dangerMode: true,
  }).then((willReload) => {
    if (willReload) {
      swal({
        title: 'Are you sure?',
        text: 'You will lose any unsaved changes if you continue.',
        icon: 'warning',
        buttons: ['Cancel', 'Reload Page'],
        dangerMode: true,
      }).then((confirmReload) => {
        if (confirmReload) {
          window.location.reload();
        }
      });
    }
  });
}

function checkVersion(cssJsSources: string[]) {
  $.ajax({ url: '/', cache: false, dataType: 'text' })
    .done((data) => {
      if (cssJsSources.length > 0) {
        const allSourcesIncluded = cssJsSources.every((source) => data.includes(source));

        if (!allSourcesIncluded) {
          showUpdateAlert();
        }
      } else {
        console.warn('No CSS/JS sources found for version check');
      }
    })
    .fail(() => console.log('Failed to check for new version.'));
}
