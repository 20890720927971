import LeaveRequest from '@/core/entities/LeaveRequest';
import LeaveRequestRepository from '@/core/features/leave-requests/interfaces/LeaveRequestRepository';
import { DataFilterInterface } from '@/core/interfaces/common/filters';
import * as FirestoreService from '@/services/firebase/FirestoreService.js';
import firebase from 'firebase';

export default class LeaveRequestRepositoryFirestoreImpl implements LeaveRequestRepository {
  private collectionName = 'vacationRequests';
  createLeaveRequest(data: LeaveRequest): Promise<LeaveRequest> {
    const firestore = firebase.firestore();
    const leaveRequestDocument = firestore.collection(this.collectionName).doc();
    const leaveRequestData = { ...data, id: leaveRequestDocument.id };
    return leaveRequestDocument.set(leaveRequestData).then(() => new LeaveRequest(leaveRequestData));
  }

  updateLeaveRequest({ id, data }: { id: any; data: any }): Promise<LeaveRequest> {
    return FirestoreService.saveRecord({
      collectionName: this.collectionName,
      id,
      data,
    } as any).then((id) => {
      return new LeaveRequest({ ...data, id });
    });
  }

  getLeaveRequest({ id }: { id: string }): Promise<LeaveRequest> {
    const firestore = firebase.firestore();
    const document = firestore.collection(this.collectionName).doc(id);
    return document
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error('Leave request does not exist');
        }

        return {
          id: doc.id,
          ...doc.data(),
        } as any;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  getLeaveRequests(query: DataFilterInterface): Promise<LeaveRequest[]> {
    return FirestoreService.getRecords({
      collectionName: this.collectionName,
      whereStatements: query.filters.map(({ field, operator, value }) => [field, operator, value]),
      populations: {
        employee: {
          collectionName: 'contacts',
        },
      },
    }).then((records) => records);
  }
}
