import { Callback, Observer, Snapshot } from './snapshot';

const DEFAULT_TTL_MINS = 15;

export class SnapshotRepository {
  private readonly snapshots = new Map<string, Snapshot>();

  private static instance: SnapshotRepository;

  private constructor() {}

  public static getInstance(): SnapshotRepository {
    if (!SnapshotRepository.instance) {
      SnapshotRepository.instance = new SnapshotRepository();
    }
    return SnapshotRepository.instance;
  }

  public registerSnapshot(key: string, unsubscribe: () => void, ttlMins: number = DEFAULT_TTL_MINS): void {
    if (this.snapshots.has(key)) {
      throw new Error(`Cannot register subscription. Snapshot for ${key} already exists`);
    }

    const snapshot = new Snapshot(key, unsubscribe, ttlMins);
    this.snapshots.set(key, snapshot);
  }

  public removeSnapshot(key: string): void {
    const snapshot = this.snapshots.get(key);
    if (snapshot) {
      snapshot.unsubscribe();
      this.snapshots.delete(key);
    }
  }

  public hasSnapshot(key: string): boolean {
    return this.snapshots.has(key);
  }

  public getSnapshot(key: string): Snapshot | undefined {
    return this.snapshots.get(key);
  }

  public addObserver(key: string, callback: Callback): string {
    const snapshot = this.snapshots.get(key);
    if (!snapshot) {
      throw new Error(`Cannot add observer to snapshot. Snapshot for ${key} does not exist`);
    }

    const observer = new Observer(callback);
    snapshot.observers.push(observer);
    return observer.id;
  }

  public removeObserver(snapshotKey: string, observerId: string): void {
    const snapshot: Snapshot = this.snapshots.get(snapshotKey);
    if (!snapshot) {
      throw new Error(`Cannot remove observer from snapshot. Snapshot for ${snapshotKey} does not exist`);
    }

    snapshot.observers = snapshot.observers.filter((observer) => observer.id !== observerId);
    if (snapshot.observers.length === 0) {
      this.removeSnapshotAfterTTL(snapshot);
    }
  }

  /**
   * Publishes data to observers of a specific snapshot
   * @param key The snapshot key
   * @param data The data to publish
   */
  public publish<T>(key: string, data: T): void {
    const snapshot = this.snapshots.get(key);
    if (!snapshot) {
      return;
    }

    console.debug(`Publishing data for ${snapshot.key}`);
    snapshot.data = data;
    snapshot.observers.forEach((observer: Observer) => {
      observer.callback(data);
    });
  }

  private removeSnapshotAfterTTL(snapshot: Snapshot): void {
    if (!snapshot.ttlMins || snapshot.ttlMins <= 0) {
      this.removeSnapshot(snapshot.key);
      return;
    }

    if (snapshot.ttlTimeout) {
      clearTimeout(snapshot.ttlTimeout);
    }

    snapshot.ttlTimeout = setTimeout(
      () => {
        if (snapshot.observers.length === 0) {
          console.debug(`Firestore onSnapshot TTL expired for ${snapshot.key}`);
          this.removeSnapshot(snapshot.key);
        }
      },
      snapshot.ttlMins * 60 * 1000,
    );
  }

  public clear(): void {
    console.debug('Clearing firestore snapshots');
    this.snapshots.forEach((snapshot: Snapshot) => {
      snapshot.unsubscribe();
    });
    this.snapshots.clear();
  }
}
