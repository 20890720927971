import { querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  projectEngines: [],
  subscription: null,
};

export const getters = {
  projectEngines: ({ projectEngines }) => projectEngines,
  projectEngine: (state) => (id) => state.projectEngines.find((projectEngine) => projectEngine.id === id),
};

export const mutations = {
  SET_PROJECT_ENGINES(state, projectEngines) {
    state.projectEngines = projectEngines;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchProjectEngines({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('projectEngines');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_PROJECT_ENGINES', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveProjectEngine(context, projectEngineOptions) {
    return saveRecord(projectEngineOptions).then((id) => id);
  },
  fetchProjectEngine({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.projectEngine(id)) {
        resolve(getters.projectEngine(id));
        return;
      }

      const projectEngineDocument = firestoreDocument('projectEngines')(id);
      projectEngineDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
