import { firestoreDocument } from '@/services/utils/Utils.js';

import firebase from 'firebase';

// Storage handler
import { pictureUpload } from '@/services/firebase/StorageService';
import { getRecords } from '@/services/firebase/FirestoreService';
import { currentUser } from '@/services/utils/Utils';

export const namespaced = true;

export const state = {
  contacts: [],
  subscription: null,
};

export const getters = {
  contacts: ({ contacts }) => contacts,
  contact: (state) => (id) => state.contacts.find((contact) => contact.id === id),
};

export const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
};

// statements variable must be an array of where statements,
// see example on QuarterlyEvaluationForm.vue file
export const actions = {
  async fetchContacts({ commit }, whereStatements = []) {
    return getRecords({
      collectionName: 'contacts',
      whereStatements,
    }).then((contacts) => {
      commit('SET_CONTACTS', contacts);
    });
  },
  saveContact: async (context, { id, record, profilePicture, driverLicenseImageFront, driverLicenseImageBack, positionAgreement }) => {
    try {
      if (!(record && typeof record === 'object' && !Array.isArray(record))) {
        throw Error('invalid record type');
      }

      const storage = firebase.storage();
      const contactDocument = firestoreDocument('contacts')(id);

      // The actual saved images (an image url)
      let savedProfilePicture = { ...record.profilePicture };
      let driverLicenseBack = { ...record.driverLicenseImageBack };
      let driverLicenseFront = { ...record.driverLicenseImageFront };
      let savedPositionAgreement = { ...record.positionAgreement };

      if (profilePicture) {
        if (savedProfilePicture.url) {
          const ref = storage.refFromURL(savedProfilePicture.url);
          await ref.delete();
          console.log('Deleted Back');
        }

        const request = await pictureUpload([profilePicture], `picture_uploads/contacts/${contactDocument.id}/profile_picture`);

        savedProfilePicture = request[0];
      }

      if (driverLicenseImageBack) {
        if (driverLicenseBack.url) {
          const ref = storage.refFromURL(driverLicenseBack.url);
          await ref.delete();
          console.log('Deleted Back');
        }

        const request = await pictureUpload([driverLicenseImageBack], `picture_uploads/contacts/${contactDocument.id}/driver_license`);

        driverLicenseBack = request[0];
      }

      if (driverLicenseImageFront) {
        if (driverLicenseFront.url) {
          const ref = storage.refFromURL(driverLicenseFront.url);
          await ref.delete();
          console.log('Deleted Front');
        }

        const request = await pictureUpload([driverLicenseImageFront], `picture_uploads/contacts/${contactDocument.id}/driver_license`);
        driverLicenseFront = request[0];
      }

      if (positionAgreement) {
        if (savedPositionAgreement.url) {
          const ref = storage.refFromURL(savedPositionAgreement.url);
          await ref.delete();
          console.log('Deleted Position Agreement File');
        }

        const request = await pictureUpload([positionAgreement], `picture_uploads/contacts/${contactDocument.id}/position_agreement`);
        savedPositionAgreement = request[0];
      }

      if (record.policyAcknowledgment?.length && record.policyAcknowledgment.some((policy) => policy.file.file)) {
        record.policyAcknowledgment = await Promise.all(
          record.policyAcknowledgment.map(async (policy) => {
            if (policy.file.file) {
              const request = await pictureUpload([policy.file], `picture_uploads/contacts/${contactDocument.id}/policy_acknowledgment`);
              return {
                ...policy,
                file: request[0],
              };
            }

            return policy;
          }),
        );
      }

      await contactDocument.set({
        ...record,
        driverLicenseImageBack: driverLicenseBack,
        driverLicenseImageFront: driverLicenseFront,
        positionAgreement: savedPositionAgreement,
        profilePicture: savedProfilePicture,
      });

      return contactDocument.id;
    } catch (error) {
      throw new Error(error);
    }
  },
  fetchContact(context, id) {
    const contactDocument = firestoreDocument('contacts')(id);
    return contactDocument
      .get()
      .then((querySnapshot) => ({
        ...querySnapshot.data(),
        id: querySnapshot.id,
      }))
      .catch((error) => error);
  },
  archiveContact(context, { id, archived }) {
    if (!id || typeof archived !== 'boolean') {
      throw Error('ID and archived properties are required.');
    }

    const contactDocument = firestoreDocument('contacts')(id);
    return contactDocument.update({
      archived,
      modifiedBy: currentUser().uid,
      modifiedDate: new Date().toISOString(),
    });
  },
};
