// Firebase
import Vue from 'vue';
import { OrgChartTier } from '@/components/orgchart/OrgChartHelper';

const processAssignments = function ({ departments, people, assignments }) {
  departments.forEach((dept) => {
    const manager = people.find((p) => p.id === dept.manager_id);
    if (manager) {
      if (!manager.departments) {
        manager.departments = [{ role: 'Manager', department: dept }];
      } else {
        manager.departments.push({
          role: 'Manager',
          department: dept,
        });
      }
    }
    let assign = assignments.filter((ass) => dept.id === ass.department_id);
    let assignmentstotal = [];
    assign.forEach((ass) => {
      let p = people.find((p) => p.id === ass.person_id);
      if (!p.departments) {
        p.departments = [{ role: ass.role, department: dept }];
      } else {
        p.departments.push({ role: ass.role, department: dept });
      }
      assignmentstotal.push({ person: p, role: ass.role });
    });
    dept.employees = assignmentstotal;

    dept.employees.sort((a, b) => (a.person.name > b.person.name ? 1 : b.person.name > a.person.name ? -1 : 0));
  });
  //departments.sort((a, b) => )
};
function processData10(dept, orgArray) {
  if (!dept.employees) {
    Vue.set(dept, 'employees', []);
  }

  orgArray.push(dept);
  // var manager = INPUT_DATA.people.find(p => p.id == dept.manager_id)
  // dept.manager = manager ? manager : { name: '' }
  // dept.dataFields = dept.dataFields
  dept.showChildren = false;
  dept.showParents = true;
  dept.onlyParents = false;
  dept.onlyShowThisChild = null;
  dept.children.forEach((c) => {
    // c.parent = dept
    processData10(c, orgArray);
  });
  return { dept: dept, orgArray: orgArray };
}

function findDept(chart, dept) {
  if (chart === dept) {
    return dept;
  } else {
    var fnd = null;
    for (let child of chart.children) {
      fnd = findDept(child, dept);
      if (fnd) break;
    }
    return fnd;
  }
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function delDept(state, department) {
  if (department.children.length) {
    department.children.forEach((child) => {
      delDept(state, child);
    });
  }
  // unassign manager from department
  const manager = people.find((p) => p.id === department.manager_id);
  if (department.manager_id && manager) {
    manager.departments = manager.departments.filter((d) => d.department !== department);
  }
  //unassign each employee from department
  department.employees.forEach((e) => {
    e.person.departments = e.person.departments.filter((pd) => pd.department !== department);
  });
  // delete department
  state.orgArray = state.orgArray.filter((d) => d !== department);
}

const state = {
  // the zoom plugin instance
  panzoomInstance: null,
  // organization departments
  orgArray: null,
  // lines in graph
  // lines: [],
  // current root of the tree
  chart: null,
  // all people
  people: [],
  // staff assignments
  assignments: [],
  // show all children collapsed in a column for space
  columnView: false,

  config: {
    boxHeight: 90,
    boxWidth: 170,
    levelColors: OrgChartTier.reduce((c, { name, color }) => ((c[name] = color), c), {}),
  },

  // enable edit mode
  editMode: false,
  // the position for the edit menu
  showEditMenu: null,
  // show the number of departments under you. (since we add staff as department, it would mean direct report)
  showNrDepartments: true,
  // used for highlighting
  activeDepartment: null,
  // used for moving a department
  moveDepartment: null,
  //showNrPeople: false,
  // used for hiding siblings (the plugin doesn't reset this at all)
  onlyShowParents: false,

  // mark that there are some pending edits
  dirty: false,
};
const getters = {
  orgArrayAssoc: function ({ orgArray }) {
    return orgArray.reduce((c, e) => {
      c[e.id] = e;
      return c;
    }, {});
  },
  peopleAssoc: function ({ people }) {
    return people.reduce((c, e) => {
      c[e.id] = e;
      return c;
    }, {});
  },
};
const actions = {
  initStore({ commit, state }, INPUT_DATA) {
    const data = processData10(INPUT_DATA.chart, []);
    // data.dept.showChildren = true;
    const people = INPUT_DATA.people;

    processAssignments({
      departments: data.orgArray,
      people: people,
      assignments: INPUT_DATA.assignments,
    });
    commit('setDirty', false);
    commit('setData', { data, people, assignments: INPUT_DATA.assignments });
  },
  showChildren({ commit, state }, dept) {
    commit('setActiveDepartment', null);
    commit('showChildren', dept);
    commit('setActiveDepartment', dept);
    // refreshLines(this)
  },
  hideChildren({ commit, state }, dept) {
    commit('hideChildren', dept);
    // refreshLines(this)
  },
  toggleHideParents({ commit, state, dispatch }) {
    commit('toggleHideParents');
    // refreshLines(this, state.activeDepartment)
  },
  setHideParents({ commit, state, dispatch }, value) {
    commit('setHideParents', value);
    // refreshLines(this, state.activeDepartment)
  },
  deleteDepartment({ commit, state }, dept) {
    commit('deleteDepartment', dept);
  },
  addDepartment({ commit, state }, dept) {
    commit('addDepartment', dept);
  },
  doMoveDepartment({ commit, state }) {
    commit('doMoveDepartment');
  },
};
const mutations = {
  setData(state, { data, people, assignments }) {
    state.orgArray = data.orgArray;
    // datas.orgArray.forEach(d => state.orgArray.push(d))
    state.chart = data.dept;
    state.chart.showChildren = true;
    state.people = people;
    assignments.forEach((d, i) => {
      d.id = guid();
    });
    state.assignments = assignments;
  },
  setPanZoomInstance(state, value) {
    state.panzoomInstance = value;
  },
  setEditMode(state, value) {
    state.editMode = value;
  },
  showEditMenu(state, event) {
    state.showEditMenu = event;
  },
  showViewMenu(state, event) {
    state.showViewMenu = event;
  },
  setActiveDepartment(state, dept) {
    if (state.chart.parent_id && dept && !findDept(state.chart, dept)) {
      state.chart = state.orgArray.find((e) => !e.parent_id);
    }
    state.activeDepartment = dept;
  },
  showChildren(state, dept) {
    dept.showChildren = true;
  },
  hideChildren(state, dept) {
    dept.showChildren = false;
    //updates local copy
    //not sure why
    const index = state.orgArray.findIndex((e) => e.id === dept.id);
    state.orgArray.splice(index, 1, dept);
  },
  setHideParents(state, val) {
    state.activeDepartment.showParents = !val;
    if (val) {
      state.chart = state.activeDepartment;
    } else {
      //set to root department
      state.chart = state.orgArray.find((e) => !e.parent_id);
    }
  },
  toggleHideParents(state) {
    state.activeDepartment.showParents = !state.activeDepartment.showParents;
    if (!state.activeDepartment.showParents) {
      state.chart = state.activeDepartment;
    } else {
      state.chart = state.orgArray.find((e) => !e.parent_id);
    }
  },
  reset(state) {
    state.activeDepartment = null;
    state.chart = state.orgArray.find((e) => !e.parent_id);
    state.chart.showChildren = true;
    state.chart.children.forEach((child) => (child.showChildren = false));
  },
  deleteDepartment(state, department) {
    // do not remove if this is the ultimate top department
    let children = [];
    // unlink this department from the parent
    let parent;
    if (department.parent_id && (parent = state.orgArray.find((d) => d.id === department.parent_id))) {
      children = parent.children.filter((child) => child !== state.activeDepartment);
      parent.children = children;
    }

    delDept(state, department);

    state.activeDepartment = null;
    state.showEditMenu = null;
  },
  addDepartment(state) {
    const newDept = {
      id: guid(),
      name: '',
      description: '',
      parent_id: state.activeDepartment.id,
      isStaff: false,
      manager_id: '',
      positionType: '',
      level: '',
      children: [],
      showChildren: false,
      employees: [],
    };

    state.activeDepartment.children.push(newDept);
    // state.activeDepartment.parent.showChildren = true
    state.activeDepartment.showChildren = true;
    state.activeDepartment = newDept;
    state.showEditMenu = null;
  },
  setMoveDepartment(state) {
    state.moveDepartment = state.activeDepartment;
  },
  cancelMoveDepartment(state) {
    state.moveDepartment = null;
  },
  doMoveDepartment(state) {
    //check that the department is not pasted under one of its children
    let departmentIterator = state.activeDepartment;
    let isSelfOrChild = false;
    do {
      if (departmentIterator === state.moveDepartment) isSelfOrChild = true;
      departmentIterator = state.orgArray.find((dep) => dep.id === departmentIterator.parent_id);
    } while (departmentIterator && !isSelfOrChild);

    if (isSelfOrChild) {
      alert('cannot paste under itself (or children)');
      return;
    }

    const parent = state.orgArray.find((dep) => dep.id === state.moveDepartment.parent_id);
    parent.children = parent.children.filter((d) => d !== state.moveDepartment);
    state.moveDepartment.parent_id = state.activeDepartment.id;
    state.activeDepartment.children.push(state.moveDepartment);
    state.activeDepartment = state.moveDepartment;
    state.moveDepartment = null;
    state.showEditMenu = null;
  },
  toggleColumnView(state) {
    state.columnView = !state.columnView;
  },
  setShowDepartment(state, dept) {
    state.chart = state.orgArray.find((e) => !e.parent);
    let p = (dept.parent_id && state.orgArray.find((e) => e.id === dept.parent_id)) || null;
    if (p && state.onlyShowParents) {
      p.onlyShowThisChild = dept;
    } else if (p) {
      p.onlyShowThisChild = null;
    }
    dept.onlyShowThisChild = null;
    while (p) {
      p.showChildren = true;

      const parent = (p.parent_id && state.orgArray.find((e) => e.id === p.parent_id)) || null;
      if (state.onlyShowParents && parent) {
        parent.onlyShowThisChild = p;
      } else if (parent) {
        parent.onlyShowThisChild = null;
      }
      p = parent;
    }
    state.activeDepartment = dept;
  },
  updateActiveDepartment(state, props) {
    state.dirty = true;
    Object.entries(props).forEach(([key, value]) => (state.activeDepartment[key] = value));
  },
  addPeople(state, contact) {
    state.dirty = true;
    state.people.push(contact);
  },
  setDirty(state, dirty) {
    state.dirty = dirty;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
