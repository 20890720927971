const items2023 = {
  Overview: {
    title: 'Overview',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FOpen%20Enrollment%20LightEdison.pdf?alt=media&token=23cb3ce7-64d5-48bf-9ddc-54098171224f',
  },
  Medical: {
    title: 'Medical',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FLightEdison%20Plan%20Outline.pdf?alt=media&token=38253ca4-d2e8-4ed4-837d-d53588d296b1',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://na2.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDscJMjHLFr1x3IeVRlNuNaPsskBnm80H-BRtsYPm5rBEZJ6cXkFMhpMWLi9gm2Cxs*',
    },
  },
  DentalVisionLife: {
    title: 'Dental, Vision, Life',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FWelcome%20to%20Guardian.pdf?alt=media&token=b7cb0ed7-d1ed-416c-9f5b-b0a9e1007cd2',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://secure.rightsignature.com/templates/5489b954-2ccd-42cc-b745-4c87a8448117/template-signer-link/6432a0a7d7608fcf2db5331a986afa6f',
    },
  },
  HSA: {
    title: 'HSA',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2Fhsa-winning.pdf?alt=media&token=13d23a89-3462-42c2-af98-c1d53ad479c4',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://na2.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhD0m56pK_pLfsT8fKfoGqm1P-3Z2lQf9N5biOcPvLG9IIACH8mTFw0GMTd4FP17Ods*',
    },
  },
  '401k': {
    title: '401k',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F1%20LE%2C%20LightEdison%20401(k)%20Brochure%20and%20Enrollment%2012-20-22.pdf?alt=media&token=720a1fab-4d68-4ce5-8944-97c7ca56935d',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://myretirementbenefit.voya.com/17ao',
    },
  },
  // EnrollmentPacketMedical: {
  //   title: "Enrollment Packet Medical",
  //   subtitle: false,
  //   url: "",
  // },
  // EnrollmentPacketHSA: {
  //   title: "Enrollment Packet HSA",
  //   subtitle: false,
  //   url: "",
  // },
  // EnrollmentPacketDentalAndVision: {
  //   title: "Enrollment Packet Dental and Vision",
  //   subtitle: false,
  //   url: "",
  // },
  RateCalculator: {
    title: 'Rate Calculator',
    subtitle: false,
    url: false,
    enrollBtn: {
      title: 'Open in Google Sheets?',
      url: 'https://docs.google.com/spreadsheets/d/1Wum6O5AU6iuPglPSjRwEQqUAmKOhtgyw2R2y_kZNsrE/copy',
    },
  },
};

const items2024 = {
  Overview: {
    title: 'Overview',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FOpen%20Enrollment%20Overview%202024%20(2).pdf?alt=media&token=723ac31b-22b3-47bf-a13c-6df1a0c45610',
  },
  Medical: {
    title: 'Medical',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FLE%20Plan%20Outlines.pdf?alt=media&token=cbf07ed5-caa3-4869-9db6-0be8d0245df9',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://secure.rightsignature.com/templates/afd397b1-bea7-44de-9cf6-1c09df2eff54/template-signer-link/ab580c3330bc82176ade31ac17a071a8',
    },
  },
  DentalVisionLife: {
    title: 'Dental, Vision, Life',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FbenefitSummary.pdf?alt=media&token=4f91e42b-2a1e-4d21-aa0d-d9a000ca0045',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://secure.rightsignature.com/templates/f3baad9a-73f8-4cc0-9f66-aa914cb31200/template-signer-link/d956db16980040d67dd5219100cf09ca',
    },
  },
  HSA: {
    title: 'HSA',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2Fhsa-winning.pdf?alt=media&token=13d23a89-3462-42c2-af98-c1d53ad479c4',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://secure.rightsignature.com/templates/dd203f78-0d2a-4730-b1ad-8748cc6a8aee/template-signer-link/6ab4fad7bf3952fa6250f71d08a1e449',
    },
  },
  '401k': {
    title: '401k',
    subtitle: false,
    url: 'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FVoya%20Enrollment%20Packet.pdf?alt=media&token=ad0d21b2-89f6-484c-a4d4-14adea49f960',
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: 'https://myretirementbenefit.voya.com/17ao',
    },
  },
  RateCalculator: {
    title: 'Rate Calculator',
    subtitle: false,
    url: false,
    enrollBtn: {
      title: 'Open in Google Sheets?',
      url: 'https://docs.google.com/spreadsheets/d/1dZum0VeD_gFqr3tGimwj6t2zl2MO6cYdVofct0JO2CY/copy',
    },
  },
};

export const items = {
  2023: items2023,
  2024: items2024,
};
