export class CacheEntry<T> {
  data: T;
  expiry: number | undefined;

  constructor(data: T, ttlMins?: number) {
    this.data = data;
    this.expiry = ttlMins ? Date.now() + ttlMins * 60 * 1000 : undefined;
  }
}

export function isExpired<T>(entry: CacheEntry<T>): boolean {
  return entry.expiry && entry.expiry < Date.now();
}
