<template>
  <div class="notification-container">
    <NotificationBar v-for="notification in notifications" :notification="notification" :key="notification.id" />
  </div>
</template>

<script lang="ts">
import NotificationBar from '@/components/notifications/NotificationBar.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    NotificationBar,
  },
  computed: {
    ...mapGetters('notifications', ['notifications']),
  },
};
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 40px;
  z-index: 100;
}
</style>
