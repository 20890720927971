// Helper function to upload images on firebase storage
// storage: a firebase storage instance
// files, an array of image files
// parents: The firebase storage path excluding the file name
async function storageHandler(storage, files, parents) {
  const imagesUploadHandler = files.map((img, index) => {
    const date = new Date();
    const newFileName = date.getTime();
    const extension = img.fileName.slice(img.fileName.lastIndexOf('.'));
    const uploadPath = `${parents}/${newFileName + extension}`;

    const uploadPointer = storage.ref().child(uploadPath);
    const uploadRequest = uploadPointer.put(img.file);

    return uploadRequest;
  });

  try {
    const storageResult = await Promise.all(imagesUploadHandler);
    const imagesData = [];
    for (const result in storageResult) {
      const downloadUrl = await storageResult[result].ref.getDownloadURL();

      imagesData.push({
        name: storageResult[result].metadata.name,
        url: downloadUrl,
      });
    }

    return imagesData;
  } catch (error) {
    console.log(error);
  }
}

export { storageHandler };
