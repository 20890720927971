import RouteSOPRepository from '@/core/interfaces/repositories/RouteSOPRepository';
import RouteSOP from '@/core/entities/RouteSOP';

export default class CreateRouteSOPUseCase {
  private routeSOPRepository: RouteSOPRepository;

  constructor(routeSOPRepository: RouteSOPRepository) {
    this.routeSOPRepository = routeSOPRepository;
  }

  async execute(routeSOP: RouteSOP): Promise<RouteSOP> {
    const newRouteSOP = await this.routeSOPRepository.addRouteSOP(routeSOP);
    return newRouteSOP;
  }
}
