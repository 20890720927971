import validate from 'validate.js';
import moment from 'moment';

export default class LeaveTimeEntry {
  id?: string;

  //
  employee: string;
  startDate: string;
  vacationType: string;
  grossHours: string;
  netHours: string;
  description?: string;
  leaveRequest?: string;

  // System Fields
  createdBy: string;
  createdDate: string;
  modifiedBy?: string;
  modifiedDate?: string;

  constructor({
    id = null,
    employee = null,
    startDate = null,
    vacationType = null,
    grossHours = null,
    netHours = null,
    description = null,
    leaveRequest = null,

    //
    createdBy = null,
    createdDate = null,
    modifiedBy = null,
    modifiedDate = null,
  }) {
    this.id = id;

    //
    this.employee = employee;
    this.startDate = startDate;
    this.vacationType = vacationType;
    this.grossHours = grossHours;
    this.netHours = netHours;
    this.description = description;
    this.leaveRequest = leaveRequest;

    //
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.modifiedBy = modifiedBy;
    this.modifiedDate = modifiedDate;
  }

  validate() {
    const validate = this.setValidator();

    const presenceTrue = {
      presence: true,
    };

    //
    const constraints = {
      employee: presenceTrue,
      startDate: { ...presenceTrue },
      vacationType: presenceTrue,
      grossHours: {
        presence: true,
        numericality: {
          greaterThan: 0,
        },
      },
      netHours: {
        presence: true,
        numericality: {
          greaterThan: 0,
        },
      },
      description: presenceTrue,
    };

    return validate(this, constraints);
  }

  private setValidator() {
    // Before using it we must add the parse and format functions
    // Here is a sample implementation using moment.js
    validate.extend(validate.validators.datetime, {
      // The value is guaranteed not to be null or undefined but otherwise it
      // could be anything.
      parse: function (value, options) {
        return +moment.utc(new Date(value));
      },
      // Input is a unix timestamp
      format: function (value, options) {
        const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
        return moment.utc(new Date(value)).format(format);
      },
    });

    return validate;
  }
}
