import HolidaysRepository from '@/core/features/leave-requests/interfaces/HolidaysRepository';

export default class HolidaysRepositoryInMemoryImpl implements HolidaysRepository {
  getHolidays(year: number): Holiday[] {
    const holidays = [];
    for (let month = 0; month < 12; month++) {
      for (let week = -1; week < 5; week++) {
        for (let day = 0; day < 7; day++) {
          const date = getDate(year, month, week, day);
          const holiday = getHoliday(month, week, day);
          if (holiday) {
            holidays.push({
              date: date.toISOString().split('T')[0],
              name: holiday,
            });
          }
        }
      }
    }

    function rotateDateIfWeekend(date: Date) {
      const isSaturday = date.getUTCDay() === 6;
      const isSunday = date.getUTCDay() === 0;

      date.setDate(date.getUTCDate() + (isSaturday ? -1 : isSunday ? 1 : 0));
      return date.toISOString().split('T')[0];
    }

    // Append fixed holidays, and shift them if they fall on a weekend
    Object.keys(fixedHolidays).forEach((holidayName) => {
      const holidayDate = fixedHolidays[holidayName];
      const date = new Date(year, holidayDate.month, holidayDate.date);
      holidays.push({
        date: rotateDateIfWeekend(date),
        name: holidayName,
      });
    });

    // Check if New Year's Day for next year is shifted to the previous year (e.g. 2021-01-01 is a Saturday, so it's shifted to 2020-12-31)
    const newYearsDayConfig = fixedHolidays["New Year's Day"];
    const newYearsDayDate = new Date(year + 1, newYearsDayConfig.month, newYearsDayConfig.date);
    const newYearsDayShited = rotateDateIfWeekend(newYearsDayDate);
    const belongsToPreviousYear = newYearsDayShited.split('-')[0] == String(year);
    if (belongsToPreviousYear) {
      holidays.push({
        date: newYearsDayShited,
        name: "New Year's Day",
      });
    }

    return holidays;
  }

  isHoliday({ date: dateString }: { date: string }): boolean {
    const date = new Date(dateString as any);
    const year = date.getFullYear();
    const holidays = this.getHolidays(year);
    return !!holidays.find((holiday) => holiday.date === dateString);
  }

  getHolidayInstance({ date: dateString }: { date: string }): Holiday {
    const formatDate = (date: string = '') => date.replace(/-/g, '/');
    const date = new Date(formatDate(dateString as any));
    const year = date.getFullYear();

    const holidays = this.getHolidays(year);
    return holidays.find((holiday) => holiday.date === dateString) || null;
  }
}

/** Reference from */
const holidays = {
  // keys are formatted as month,week,day
  '4,-1,1': 'Memorial Day',
  '8,0,1': 'Labor Day',
  '10,3,4': 'Thanksgiving Day',
};

const fixedHolidays = {
  Christmas: { month: 11, date: 25 },
  "New Year's Day": { month: 0, date: 1 },
  'Independence Day': { month: 6, date: 4 },
};

function getDate(year, month, week, day) {
  const firstDay = 1;
  if (week < 0) {
    month++;
  }
  const date = new Date(year, month, week * 7 + firstDay);
  if (day < date.getUTCDay()) {
    day += 7;
  }
  date.setDate(date.getUTCDate() - date.getUTCDay() + day);
  return date;
}

function getHoliday(month, week, day) {
  return holidays[month + ',' + week + ',' + day];
}

interface Holiday {
  date: string;
  name: string;
}
