import LeaveRequestRepository from '../interfaces/LeaveRequestRepository';
import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import LeaveRequest from '@/core/entities/LeaveRequest';

export default class CreateLeaveRequest {
  private leaveRequestRepository: LeaveRequestRepository;
  private authenticationGateway: AuthenticationGateway;

  constructor(leaveRequestRepository: LeaveRequestRepository, authenticationGateway: AuthenticationGateway) {
    this.leaveRequestRepository = leaveRequestRepository;
    this.authenticationGateway = authenticationGateway;
  }

  async execute(data: any): Promise<LeaveRequest> {
    const { uid } = await this.authenticationGateway.getCurrentUser();
    const createdDate = new Date().toISOString();
    const createdSystemFields = { createdBy: uid, createdDate };

    const leaveRequest = new LeaveRequest({
      ...data,
      ...createdSystemFields,
    });
    const isInvalid = leaveRequest.validate();

    if (isInvalid) {
      throw new Error('Invalid Leave Request Data', { cause: isInvalid });
    }
    const newLeaveRequest = await this.leaveRequestRepository.createLeaveRequest(leaveRequest);
    return newLeaveRequest;
  }
}
