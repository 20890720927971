import { firestoreDocument, querySnapshotToArray } from '@/services/utils/Utils.js';
import { saveRecordWithPictures } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  services: [],
  subscription: null,
};

export const getters = {
  services: ({ services }) => services,
  service: (state) => (id) => state.services.find((service) => service.id === id),
};

export const mutations = {
  SET_SERVICES(state, services) {
    state.services = services;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchServices({ commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('services');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_SERVICES', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveService(context, serviceOptions) {
    return saveRecordWithPictures(serviceOptions).then((id) => id);
  },
  fetchService(context, id) {
    return new Promise((resolve, reject) => {
      const serviceDocument = firestoreDocument('services')(id);
      serviceDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
