import { Role } from '@/models/role';
import firebase from 'firebase';
import { firestoreService } from './firebase/db/firestoreService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Claims = { [key: string]: any };

export default class UserService {
  private static idToken: firebase.auth.IdTokenResult;

  public static getCurrentUser(): firebase.User {
    return firebase.auth().currentUser;
  }

  public static async getIdToken(): Promise<firebase.auth.IdTokenResult> {
    if (!this.idToken) {
      this.idToken = await this.getCurrentUser().getIdTokenResult();
    }
    return this.idToken;
  }

  public static async getClaims(): Promise<Claims> {
    return (await this.getIdToken()).claims;
  }

  public static async getRoleId(): Promise<string> {
    return (await this.getClaims()).role;
  }

  public static async getUserRole(): Promise<Role> {
    const roleId = await this.getRoleId();
    return firestoreService.getDocument<Role>('roles', roleId);
  }

  public static async getRoleById(id: string): Promise<Role> {
    return firestoreService.getDocument<Role>('roles', id);
  }

  public static async getAllRoles(): Promise<Role[]> {
    return firestoreService.getCollection<Role>('roles');
  }

  public static subscribeToRoles(onUpdate: (roles: Role[]) => void): () => void {
    return firestoreService.subscribeToCollection('roles', onUpdate);
  }

  public static deleteRole(roleId: string): Promise<void> {
    return firestoreService.deleteDocument('roles', roleId);
  }
}
