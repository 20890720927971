<template>
  <base-custom-input class="tiptap-wrapper" :errors="errors" v-bind="$attrs" :required="required" :label="label">
    <template v-for="(_, field) in $scopedSlots" v-slot:[field]="slotProps">
      <slot :name="field" v-bind="slotProps"></slot>
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"></slot>
    </template>

    <template v-slot:label>
      <slot name="label" :required="required">
        {{ label }}
        <label v-if="required" class="red--text">*</label>
      </slot>
    </template>

    <template #default="{ activators }">
      <template v-if="!isDisabled">
        <vuetify-tiptap
          v-on="activators"
          v-model="internalValue"
          @blur="$emit('input', internalValue)"
          :placeholder="placeholder"
          :toolbar="toolbarOptions"
        >
          <template #editor="{ editor }">
            <editor-content class="vuetify-pro-tiptap-editor__content markdown-body" :editor="editor" data-testid="value" />
          </template>
        </vuetify-tiptap>
      </template>
      <template v-else>
        <div class="vuetify-pro-tiptap-editor__content markdown-body disabled-editor" v-html="internalValue"></div>
      </template>
    </template>
  </base-custom-input>
</template>

<script lang="ts">
import { EditorContent } from '@tiptap/vue-2';
import _debounce from 'lodash/debounce';
import 'vuetify-pro-tiptap/lib/style.css';
import { DEFAULT_TOOLBAR } from './TextEditorToolbarOptions';

export default {
  name: 'BaseRichTextEditor',
  components: { EditorContent },
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      default: () => DEFAULT_TOOLBAR,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      internalValue: '',
      updateValue: null,
    };
  },
  inject: {
    form: {
      name: 'form',
      default: null,
    },
  },
  computed: {
    isDisabled: function () {
      return this.disabled || (this.form?.disabled ?? false);
    },
    toolbarOptions() {
      return this.toolbar;
    },
  },
  watch: {
    modelValue(value) {
      if (this.internalValue !== value) {
        this.internalValue = value;
      }
    },
    internalValue(_) {
      if (!this.updateValue) {
        this.updateValue = _debounce(() => this.$emit('input', this.internalValue), 1000);
      }
      this.updateValue();
    },
  },
  // fixes the issue of the editor not updating when the component is reloaded
  created: function () {
    this.internalValue = this.modelValue;
  },
};
</script>

<style>
.tiptap-wrapper.v-text-field--outlined .v-input__slot {
  padding-bottom: 12px;
}
.tiptap-wrapper.v-text-field--outlined > .v-input__control > .v-input__slot > .v-label {
  margin-bottom: 15px;
}
.vuetify-pro-tiptap-editor {
  background-color: #ffffff !important;
}
.vuetify-pro-tiptap-editor__content.markdown-body hr {
  border-top: 1px solid #000000;
  background-image: none;
}
.vuetify-pro-tiptap-editor__content.markdown-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  em,
  code,
  strong,
  hr {
    color: unset !important;
    &::before,
    &::after {
      content: none;
      padding: 0;
    }
  }
}
.disabled-editor {
  opacity: 0.7;
  cursor: default;
  user-select: none;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.06);
}
</style>
