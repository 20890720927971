import firebase from 'firebase';
import Vue from 'vue';
import router from '@/router';
import { templateAsFlatObject } from '@/components/projectFinance/ProjectFinanceHelper';

const namespaced = true;

const columns = [
  { text: 'GC', value: 'GrossCost' },
  { text: 'LBOP', value: 'LaborOverhead' },
  { text: 'Tax', value: 'Taxes' },
  { text: 'Cont', value: 'Contingency' },
  { text: 'NC ($)', value: 'BudgetedCost' },
  { text: 'NC ($/unit)', value: 'BudgetedCost', dpu: true },
  { text: 'Construction Interest', value: 'ConstructionInterest' },
  { text: 'Bonding', value: 'Bonding' },
  { text: 'Insurance', value: 'Insurance' },
  { text: 'F', value: 'FinanceOverhead' },
  { text: 'NLC ($)', value: 'FinancedCost' },
  { text: 'NLC ($/unit)', value: 'FinancedCost', dpu: true },
  { text: 'GP', value: 'GrossProfit' },
  { text: 'FEP ($)', value: 'NetCost' },
  { text: 'FEP ($/unit)', value: 'NetCost', dpu: true },
  { text: 'Notes', value: null, notes: true },
  { text: 'Log', value: null, log: true },
].map((item, index) => ({ ...item, index }));
const state = {
  opportunityID: null,
  subs: {},

  opportunity: null,
  pfRecord: [],
  groupVersions: {},
  showOnlyNonEmpty: true,
  collapsed: {
    __total: false,
  },
  template: {},
  rateTable: null,
  selectedVersions: {},
  enabledSelectedVersions: {},
  viewableSelectedVersions: {},
  projectGroupFinance: {},
  projectGroupFinanceDetails: {},

  columns: columns,
  selectedColumns: columns.filter((column) => column.value !== null),
};

const getters = {
  sortedSelectedColumns: ({ selectedColumns }) => {
    return [...selectedColumns].sort((a, b) => a.index - b.index);
  },
  groups: ({ pfRecord }) => pfRecord?.groups || [],
  templateAsFlatObject: ({ template }) => templateAsFlatObject(template),
};

const mutations = {
  unsub(state) {
    const subs = state.subs;
    state.subs = {};
    Object.values(subs).forEach((unsub) => {
      if (unsub && typeof unsub === 'function') unsub();
    });
  },
  clearTemplate(state) {
    state.template = {};
  },
  clearRecords(state) {
    state.pfRecord = null;
    state.rateTable = null;
    state.groupVersions = {};
    state.selectedVersions = {};
    state.enabledSelectedVersions = {};
    state.viewableSelectedVersions = {};
    state.projectGroupFinance = {};
    state.projectGroupFinanceDetails = {};
  },
  resetCollapsed(state) {
    state.collapsed = { __total: false };
  },
  setOpportunity(state, data) {
    Vue.set(state, 'opportunity', data);
  },
  setTemplate(state, data) {
    Vue.set(state, 'template', data);
  },
  sub(state, { sub, key }) {
    Vue.set(state.subs, key, sub);
  },
  setPFRecord(state, data) {
    Vue.set(state, 'pfRecord', data);
  },
  setGroupVersions(state, { id, data }) {
    Vue.set(state.groupVersions, id, data);
  },
  setSelectedColumns(state, data) {
    Vue.set(state, 'selectedColumns', data);
  },
  setEnabledSelectedVersion(state, { id, value }) {
    Vue.set(state.enabledSelectedVersions, id, value);
  },
  setViewableSelectedVersion(state, { id, value }) {
    Vue.set(state.viewableSelectedVersions, id, value);
  },
  removeSelectedVersion(state, id) {
    const currentVersion = state.selectedVersions[id];
    Vue.delete(state.selectedVersions, id);
    const subs = [`versionProperties_${id}_${currentVersion}`, `versionProperties_${id}_${currentVersion}_details`];
    subs.forEach((sub) => {
      if (state.subs.hasOwnProperty(sub) && state.subs[sub]) {
        state.subs[sub]();
        Vue.delete(state.subs, sub);
      }
    });
    if (state.projectGroupFinance.hasOwnProperty(id)) Vue.delete(state.projectGroupFinance, id);
    if (state.projectGroupFinanceDetails.hasOwnProperty(id)) Vue.delete(state.projectGroupFinanceDetails, id);
  },
  setProjectGroupFinance(state, { id, data }) {
    Vue.set(state.projectGroupFinance, id, data);
  },
  setRecordDetail(state, { id, path, data }) {
    if (!state.projectGroupFinanceDetails.hasOwnProperty(id)) Vue.set(state.projectGroupFinanceDetails, id, {});
    Vue.set(state.projectGroupFinanceDetails[id], path, data);
  },
  deleteRecordDetail(state, { id, path }) {
    if (state.projectGroupFinanceDetails.hasOwnProperty(id)) Vue.delete(state.projectGroupFinanceDetails[id], path);
  },
  setOpportunityID(state, id) {
    state.opportunityID = id;
  },
  toggleCollapse(state, column) {
    Vue.set(state.collapsed, column, !state.collapsed[column]);
  },
  setRateTable(state, rateTable) {
    state.rateTable = rateTable;
  },
};

const actions = {
  deinitStore({ commit }) {
    commit('unsub');
    commit('resetCollapsed');
    commit('clearTemplate');
    commit('clearRecords');
  },
  initStore({ state, commit, dispatch }, opportunityID) {
    dispatch('deinitStore');
    commit('setOpportunityID', opportunityID);
    const firestore = firebase.firestore();
    firestore
      .collection('opportunities')
      .doc(opportunityID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          swal(
            {
              title: 'Error!',
              text: 'Opportunity not found!',
              type: 'error',
              closeOnConfirm: true,
            },
            () => {
              router.push('/opportunities?where=pf');
            },
          );
        } else commit('setOpportunity', doc.data());
      });

    const templateSubscription = firestore
      .collection('projectFinance')
      .doc('template')
      .onSnapshot((doc) => {
        if (doc.exists) commit('setTemplate', doc.data());
      });
    commit('sub', { sub: templateSubscription, key: 'template' });

    const rateTableSubscription = firestore
      .collection('projectFinance')
      .doc(opportunityID)
      .collection('laborRates')
      .doc('estimationRates')
      .onSnapshot((doc) => {
        if (doc.exists) commit('setRateTable', doc.data());
      });
    commit('sub', { sub: rateTableSubscription, key: 'rateTable' });

    const projectFinanceMainDoc = firestore.collection('projectFinance').doc(opportunityID);
    const groupsSubscription = projectFinanceMainDoc.onSnapshot((doc) => {
      const data = doc.data();
      const groups = data.groups || [];
      commit('setPFRecord', data);
      return Promise.all(
        groups.map((group) => {
          if (!state.subs.hasOwnProperty(`groupVersions_${group.id}`)) {
            const sub = projectFinanceMainDoc
              .collection('groups')
              .doc(group.id)
              .onSnapshot((doc) => {
                commit('setGroupVersions', { id: group.id, data: doc.data() });
              });
            commit('sub', { sub, key: `groupVersionsDoc_${group.id}` });
          }
        }),
      );
    });
    commit('sub', { sub: groupsSubscription, key: 'groups' });
  },
  setGroupVersion({ state, commit }, { id, tag }) {
    if (state.selectedVersions.hasOwnProperty(id) && state.selectedVersions[id] !== tag) {
      commit('removeSelectedVersion', id);
    }
    if (tag) {
      Vue.set(state.selectedVersions, id, tag);
      const firestore = firebase.firestore();
      const versionDoc = firestore
        .collection('projectFinance')
        .doc(state.opportunityID)
        .collection('groups')
        .doc(id)
        .collection('versions')
        .doc(tag);
      const versionSubscription = versionDoc.onSnapshot((doc) => {
        commit('setProjectGroupFinance', { id, data: doc.data() });
      });
      commit('sub', {
        sub: versionSubscription,
        key: `versionProperties_${id}_${tag}`,
      });

      const versionDetailsSub = versionDoc.collection('projectFinanceDetails').onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          if (change.type === 'removed') {
            commit('deleteRecordDetail', { path: data.path, id });
          } else {
            commit('setRecordDetail', { id, path: data.path, data });
          }
        });
      });
      commit('sub', {
        sub: versionDetailsSub,
        key: `versionProperties_${id}_${tag}_details`,
      });
    }
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
