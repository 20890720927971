<template>
  <section>
    <v-breadcrumbs v-if="breadcrumbsItems.length" :items="breadcrumbsItems" class="py-2 px-0" divider="/"></v-breadcrumbs>
    <p class="text-h6 my-0">{{ title }}</p>
    <p v-if="subtitle" class="subtitle-1 mb-0">{{ subtitle }}</p>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    title: {
      type: [String, Number],
    },
    subtitle: [String, Number],
    breadcrumbsItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    title: function (newVal) {
      document.title = newVal;
    },
  },
  mounted() {
    document.title = this.title;
  },
};
</script>

<style></style>
