import firebase from 'firebase';

export const getContactRecordFromCurrentUser = () =>
  firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then((idTokenResult) => idTokenResult.claims.contactRecord)
    .catch((error) => error);

export const getCustomClaimsFromCurrentUser = () =>
  firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then((idTokenResult) => idTokenResult.claims)
    .catch((error) => error);
