import AuthenticationGateway from '../../auth/interfaces/AuthenticationGateway';
import LeaveRequestRepository from '../interfaces/LeaveRequestRepository';
import ContactsRepository from '@/core/features/contacts/interfaces/ContactsRepository';

// TODO: Think about a better way to handle this
import { SuperAdminRole, HeatherLloydUserID, HeatherSolleyUserID } from '@/config/variables';

export default class GetLeaveRequest {
  leaveRequestRepository: LeaveRequestRepository;
  authenticationGateway: AuthenticationGateway;
  contactsRepository: ContactsRepository;
  constructor(
    leaveRequestRepository: LeaveRequestRepository,
    authenticationGateway: AuthenticationGateway,
    contactsRepository: ContactsRepository,
  ) {
    this.leaveRequestRepository = leaveRequestRepository;
    this.contactsRepository = contactsRepository;
    this.authenticationGateway = authenticationGateway;
  }

  async execute({ id }: { id: string }) {
    const currentUser = await this.authenticationGateway.getCurrentUser();
    if (!currentUser) {
      throw new Error('Unauthorized Access');
    }

    const leaveRequest = await this.leaveRequestRepository.getLeaveRequest({
      id,
    });
    const currentUserIsOwner = leaveRequest.createdBy == currentUser.uid;

    if (!currentUserIsOwner) {
      const { contactRecord, role } = await this.authenticationGateway.getCustomClaimsFromCurrentUser();
      if (!contactRecord) {
        throw new Error('There is no contact record associated with this user');
      }

      const isSuperAdmin = role == SuperAdminRole;
      const isAllowedUser = [HeatherLloydUserID, HeatherSolleyUserID].includes(currentUser.uid);

      const leaveRequestor = await this.contactsRepository.getContact({
        id: leaveRequest.employee,
      });
      const isManagerOfRequestor = (leaveRequestor || {}).manager == contactRecord;

      if (!isManagerOfRequestor && !isSuperAdmin && !isAllowedUser) {
        throw new Error('Unauthorized Access');
      }
    }

    return leaveRequest;
  }
}
