import { getDivisionWithDepartments } from '@/services/pickers/PickersService';

const state = {
  departments: null,
};

const actions = {
  getDivisionWithDepartments({ state }) {
    if (state.departments) {
      return Promise.resolve(state.departments);
    } else {
      return getDivisionWithDepartments().then((departments) => {
        state.departments = departments;
        return departments;
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
