<template>
  <v-app>
    <!-- Profile uploading Modal -->
    <div class="modal fade" id="profileChangeModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Change Your Profile</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="name">Display Name</label>
              <input v-model="user.displayName" type="text" class="form-control" id="name" placeholder="Display Name..." />
            </div>
            <div class="form-group">
              <label for="user_photo">Photo</label>
              <input type="file" class="form-control" id="user_photo" @change="handlePictureSelection($event)" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" :disabled="isLoading" class="btn btn-info" @click="changeProfile()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Updating password Modal -->
    <div class="modal fade" id="passwordChangeModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Change Your Password</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="oldPassword">Old Password</label>
              <input v-model="password.oldPassword" type="password" class="form-control" id="oldPassword" placeholder="Old Password..." />
            </div>
            <div class="form-group">
              <label for="newPassword">New Password</label>
              <input v-model="password.newPassword" type="password" class="form-control" id="newPassword" placeholder="New Password..." />
            </div>
            <div class="form-group">
              <label for="reNewPassword">ReType New Password</label>
              <input
                v-model="password.reTypeNewPassword"
                type="password"
                class="form-control"
                id="reNewPassword"
                placeholder="ReType New Password..."
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" v-bind:disabled="isLoading" class="btn btn-info" v-on:click="changePassword()">Change Password</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Vuetify Sidebar And Header -->
    <SideBar
      ref="navigation"
      v-if="!$route.meta.plainLayout"
      @profile="updateProfileModal($event)"
      @password="updatePasswordModal()"
      @logout="logout()"
    />

    <Header v-if="!$route.meta.plainLayout" @profile="updateProfileModal($event)" @password="updatePasswordModal()" @logout="logout()" />

    <v-main>
      <v-container
        v-if="!$route.meta.plainLayout"
        :fluid="$route.matched.some((route) => !!route.meta.containerFluid)"
        :class="{
          'pa-0': $route.matched.some((route) => !!route.meta.removeContainerPadding),
          'h-100': $route.matched.some((route) => !!route.meta.fillHeight),
        }"
      >
        <router-view :key="routerKey" />
        <AppFooter />
        <NotificationsContainer />
      </v-container>
      <div v-else-if="$route.meta.plainLayout" style="height: 100vh; background-color: rgb(33, 33, 33)">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
// Firebase
import firebase from 'firebase';
import { GOOGLE_CLIENT_ID } from './config/variables.js';

// Components
import AppFooter from '@/components/fixed_components/AppFooter.vue';
import AppHeader from '@/components/fixed_components/AppHeader.vue';
import NotificationsContainer from '@/components/notifications/NotificationsContainer.vue';
import { mapActions } from 'vuex';
import Header from './components/header/Header.vue';
import SideBar from './components/sidebar/SideBar.vue';

export default {
  name: 'App',
  components: {
    NotificationsContainer,
    AppHeader,
    AppFooter,
    SideBar,
    Header,
  },
  data() {
    return {
      password: {
        oldPassword: '',
        newPassword: '',
        reTypePassword: '',
      },
      user: {
        displayName: null,
        email: null,
        photoURL: null,
        phoneNumber: null,
      },
      isLoading: false,
      userImage: null,
      routerItems: [],
    };
  },
  computed: {
    // A fix to allow for default router-view behaviour
    // (i.e. to not reload components at route change) on
    // certain modules (i.e. checklists)
    routerKey() {
      const keyByRouteName = {
        OpportunityCentric: `/opportunity/${this.$route.params.opportunityID}`,
        ProjectFinanceServices: `/projectFinance/${this.$route.params.opportunityID}/services`,
        APRecordsList: '/APRecords',
        ChecklistTemplates: '/talent_checklists',
        ChecklistTemplate: '/talent_checklists',
        NewTemplateTask: '/talent_checklists',
        TemplateTask: '/talent_checklists',
        ChecklistRecords: '/talent_checklists',
        ChecklistRecord: '/talent_checklists',
        NewChecklistTask: '/talent_checklists',
        ChecklistTask: '/talent_checklists',
        MyChecklistTasks: '/talent_checklists',
        MyChecklistTask: '/talent_checklists',
      };

      if (this.$route.matched.length) {
        return keyByRouteName[this.$route.matched[0].name] || this.$route.fullPath;
      } else {
        return this.$route.fullPath;
      }
    },
  },
  // Initializing Google Identity Services API
  beforeCreate() {
    const auth = firebase.auth();
    const GISCallbackHandler = (response) => {
      if (response.select_by == 'btn') {
        // The only google sign in button exists on the login page...

        // Build Firebase credential with the Google ID token.
        const idToken = response.credential;
        const credential = firebase.auth.GoogleAuthProvider.credential(idToken);

        const handleError = (error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;

          // Error Logging
          console.error({
            errorCode,
            errorMessage,
            email,
            credential,
          });

          const errorObject = {
            type: 'error',
            error: errorMessage,
          };

          this.addNotification(errorObject);
        };

        auth
          .signInWithCredential(credential)
          .then(() => {
            $('body').css('background-color', '#2f4050');
          })
          .catch(handleError);
      } else {
        // Mostly for authorization to use the google apis
      }
    };

    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: GISCallbackHandler,
    });
  },
  mounted() {
    const auth = firebase.auth();
    auth.onAuthStateChanged((user) => {
      if (user && this.$route.name === 'LoginComponent') {
        this.$router.replace('/home');
      }
    });
  },
  methods: {
    ...mapActions('opportunities', ['fetchOpportunities']),
    ...mapActions('contacts', ['getCurrentContact']),
    ...mapActions('notifications', ['addNotification']),
    handlePictureSelection(selectionEvent) {
      const file = selectionEvent.target.files[0];
      this.userImage = file;
    },
    updateProfileModal(event) {
      const { displayName, email, photoURL, phoneNumber } = event;
      if (!displayName || !email || !photoURL || !phoneNumber) {
        return;
      }

      this.user.displayName = displayName;
      this.user.email = email;
      this.user.photoURL = photoURL;
      this.user.phoneNumber = phoneNumber;

      $('#profileChangeModal').modal('show');
    },
    async changeProfile() {
      this.isLoading = true;

      try {
        let downloadURL;

        if (this.userImage) {
          const storage = firebase.storage();
          const reference = storage.refFromURL(this.user.photoURL);
          downloadURL = await this.uploadImage(this.userImage, 'photo_' + this.user.email);
        }

        this.user.photoURL = downloadURL ? downloadURL : this.user.photoURL;

        const { currentUser } = firebase.auth();
        await currentUser.updateProfile(this.user);

        this.isLoading = false;

        this.userImage = null;

        $('#profileChangeModal').modal('hide');

        swal('Success!', 'Profile has been updated successfully', 'success');
      } catch (error) {
        swal('Error!', error + '\nPlease Try Again', 'error');
        this.isLoading = false;
      }
    },
    updatePasswordModal() {
      $('#passwordChangeModal').modal('show');
    },
    changePassword() {
      if (this.password.oldPassword === '' || this.password.newPassword === '' || this.password.reTypeNewPassword === '') {
        return swal('Error!', 'All Fields Are Required!', 'error');
      }
      if (this.password.newPassword !== this.password.reTypeNewPassword) {
        return swal('Warning', 'Please confirm your password again', 'warning');
      }
      const cUser = firebase.auth().currentUser;
      const credentials = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, this.password.oldPassword);
      this.isLoading = true;
      cUser
        .reauthenticateAndRetrieveDataWithCredential(credentials)
        .then((uData) => {
          if (uData.operationType === 'reauthenticate') {
            cUser
              .updatePassword(this.password.newPassword)
              .then(() => {
                swal('Success!', 'Password has been updated successfully!', 'success');
                this.isLoading = false;

                this.password.oldPassword = '';
                this.password.newPassword = '';
                this.password.reTypePassword = '';

                $('#passwordChangeModal').modal('hide');
              })
              .catch((error) => {
                this.isLoading = false;

                this.password.oldPassword = '';
                this.password.newPassword = '';
                this.password.reTypePassword = '';
                swal('Error!', 'Please! Try Again: ' + error, 'error');
              });
          }
        })
        .catch((error) => {
          this.isLoading = false;

          this.password.oldPassword = '';
          this.password.newPassword = '';
          this.password.reTypePassword = '';
          swal('Error!', error, 'error');
        });
    },
    async logout() {
      const auth = firebase.auth();

      try {
        await auth.signOut();

        this.$router.push('/');
      } catch (error) {
        swal('Error!', 'Please try again: ' + error.message, 'error');
      }
    },
    async uploadImage(file, fieldName) {
      // Getting a storage reference and making file metadata
      const storageRef = firebase.storage().ref();
      const metadata = {
        contentType: 'image/jpeg',
      };
      const uploadPointer = storageRef.child('images/' + fieldName);
      const uploadTask = uploadPointer.put(file, metadata);

      //Getting notified about the file upload
      uploadTask.on('state_changed', (sp) => {
        const progress = (sp.bytesTransferred / sp.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (sp.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      });

      // Getting the download URLs from the uploaded files
      const result = await uploadTask;
      const downloadURL = await result.ref.getDownloadURL();

      return downloadURL;
    },
  },
};
</script>

<style>
select.input-sm {
  line-height: 1.5 !important;
}

.v-application .error {
  background-color: transparent !important;
  border-color: inherit !important;
}

/* .theme--light.v-application {
  background: #f3f3f4 !important;
} */

html {
  overflow-y: hidden !important;
}

body {
  scroll-padding-top: 100px;
}

select {
  width: 100%;
  background: #fff !important;
  -webkit-appearance: menulist !important;
  /*webkit browsers */
  -moz-appearance: menulist !important;
  /*Firefox */
  appearance: menulist !important;
}

/* Workaroud for word not wrapping on cards */
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

ul.vs__dropdown-menu {
  padding: 0 !important;
}

.light-edison__container {
  max-width: 900px;
  margin: auto;
}

.h-100 {
  height: 100%;
}
</style>
