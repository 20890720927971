import CalendarAdapter from '@/core/interfaces/adapters/CalendarAdapter';
declare let gapi: any;

export default class CalendarAdapterGoogleImpl implements CalendarAdapter {
  private calendarId: string;
  private accessToken: string;

  initialize({ calendarId, accessToken }) {
    if (!calendarId) {
      throw new Error('Calendar ID is required');
    }
    if (!accessToken) {
      throw new Error('Access token is required');
    }

    this.calendarId = calendarId;
    this.accessToken = accessToken;

    try {
      gapi.client.setToken({ access_token: this.accessToken });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async createEvent(event: any): Promise<any> {
    if (!this.calendarId || !this.accessToken) {
      throw new Error('Calendar ID and access token are required, you might need to initialize the adapter first');
    }

    const { title, start, end } = event;
    if (!title || !start || !end) {
      throw new Error('Title, start date, and end date are required');
    }

    const newEvent = {
      ...event,
      summary: title,
      start,
      end,
    };

    return new Promise((resolve, reject) => {
      gapi.client.calendar.events
        .insert({
          calendarId: this.calendarId,
          resource: newEvent,
        })
        .then((response: any) => {
          resolve(response.result);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
