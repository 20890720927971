import LeaveRequestsRepository from '@/core/features/leave-requests/interfaces/LeaveRequestRepository';
import LeaveTimeEntriesRepository from '@/core/features/leave-time-entries/interfaces/LeaveTimeEntriesRepository';
import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import ContactsRepository from '@/core/features/contacts/interfaces/ContactsRepository';
import CalendarAdapter from '@/core/interfaces/adapters/CalendarAdapter';
import GetLeaveRange from '@/core/features/leave-requests/use-cases/getLeaveRange';
import LeaveTimeEntry from '@/core/entities/LeaveTimeEntry';

export default class VerifyLeaveRequest {
  leaveRequestsRepository: LeaveRequestsRepository;
  leaveTimeEntriesRepository: LeaveTimeEntriesRepository;
  authenticationGateway: AuthenticationGateway;
  contactsRepository: ContactsRepository;
  getLeaveRange: GetLeaveRange;
  calendarAdapter: CalendarAdapter;

  constructor({
    leaveRequestsRepository,
    leaveTimeEntriesRepository,
    authenticationGateway,
    contactsRepository,
    getLeaveRange,
    calendarAdapter,
  }) {
    this.leaveRequestsRepository = leaveRequestsRepository;
    this.leaveTimeEntriesRepository = leaveTimeEntriesRepository;
    this.authenticationGateway = authenticationGateway;
    this.contactsRepository = contactsRepository;
    this.getLeaveRange = getLeaveRange;
    this.calendarAdapter = calendarAdapter;
  }

  async execute({ leaveRequestId, status, verificationComments }) {
    if (!leaveRequestId) {
      throw new Error('Leave request ID is required');
    }
    if (!['Approved', 'Rejected'].includes(status)) {
      throw new Error('Invalid Status');
    }
    if (!verificationComments) {
      throw new Error('Verification comments are required');
    }

    const currentUser = await this.authenticationGateway.getCurrentUser();
    if (!currentUser) {
      throw new Error('Unauthorized Access');
    }

    const { contactRecord } = await this.authenticationGateway.getCustomClaimsFromCurrentUser();
    if (!contactRecord) {
      throw new Error('There is no contact record associated with the current user');
    }

    const leaveRequest = await this.leaveRequestsRepository.getLeaveRequest({
      id: leaveRequestId,
    });

    const leaveRequestor = await this.contactsRepository.getContact({
      id: leaveRequest.employee,
    });
    const isManagerOfRequestor = (leaveRequestor || ({} as any)).manager == contactRecord;
    if (!isManagerOfRequestor) {
      throw new Error('Unauthorized Access');
    }

    if (status == 'Approved') {
      // Creating leave time entries from requested date range
      const leaveRange = this.getLeaveRange.execute({
        from: leaveRequest.startingOn,
        to: leaveRequest.endingOn,
      });
      const actualLeaveDays = leaveRange.filter((day) => !day.isHoliday && !day.isWeekend);
      const leaveTimeEntries = actualLeaveDays.map(({ date: startDate }) => {
        const leaveTimeEntry = new LeaveTimeEntry({
          startDate,
          employee: leaveRequest.employee,
          vacationType: leaveRequest.recordType,
          grossHours: '8.00',
          netHours: '8.00',
          description: leaveRequest.leaveRequestReason,
          leaveRequest: leaveRequest.id,
          createdBy: currentUser.uid,
          createdDate: new Date().toISOString(),
        });

        const isInvalid = leaveTimeEntry.validate();
        if (isInvalid) {
          throw new Error('Invalid Leave Time Entry Data', {
            cause: isInvalid,
          });
        }

        return leaveTimeEntry;
      });

      const leaveTimeEntriesPromises = leaveTimeEntries.map((leaveTimeEntry) => {
        return this.leaveTimeEntriesRepository.createLeaveTimeEntry(leaveTimeEntry);
      });
      const createdLeaveTimeEntries = await Promise.all(leaveTimeEntriesPromises);

      // (end date is exclusive, therefore we need to increment endingOn date by 1)
      const formattedEndingOn = leaveRequest.endingOn.replace(/-/g, '/');
      const incrementedEndingOn = new Date(formattedEndingOn);
      incrementedEndingOn.setDate(incrementedEndingOn.getDate() + 1);
      const incrementedFormattedEndingOn = incrementedEndingOn.toISOString().split('T')[0];

      // Creating Calendar Event
      const calendarEvent = {
        title: `${leaveRequestor.fullName} - OOO`,
        description: leaveRequest.leaveRequestReason,
        start: {
          date: leaveRequest.startingOn,
        },
        end: {
          date: incrementedFormattedEndingOn,
        },
      };
      const accessToken = await this.authenticationGateway.getAccessTokenFromCurrentUser();
      const calendarInitializationConfig = {
        calendarId: 'lightedison.com_r6g589vm4ta2ca9imr76dudjd8@group.calendar.google.com',
        accessToken,
      };
      this.calendarAdapter.initialize(calendarInitializationConfig);
      const { htmlLink } = await this.calendarAdapter.createEvent(calendarEvent);

      // Updating Leave Request
      const updatedLeaveRequest = await this.leaveRequestsRepository.updateLeaveRequest({
        id: leaveRequest.id,
        data: {
          ...leaveRequest,
          status,
          verificationComments,
          calendarEventHtmlLink: htmlLink,
          modifiedBy: currentUser.uid,
          modifiedDate: new Date().toISOString(),
        },
      });

      return {
        leaveRequest: updatedLeaveRequest,
        leaveTimeEntries: createdLeaveTimeEntries,
      };
    } else if (status == 'Rejected') {
      const updatedLeaveRequest = await this.leaveRequestsRepository.updateLeaveRequest({
        id: leaveRequest.id,
        data: {
          ...leaveRequest,
          status,
          verificationComments,
          modifiedBy: currentUser.uid,
          modifiedDate: new Date().toISOString(),
        },
      });
      return { leaveRequest: updatedLeaveRequest };
    }
  }
}
