import firebase from 'firebase';
import chunk from 'lodash/chunk.js';

const FirebaseJSONOperators = ['in', 'not-in', 'array-contains-any'];
/**
 *
 * @url https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript
 * @param {...Array} a The arrays to cross product
 * @return {Array} The cross product
 */
const cartesian = (...a) => a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));

/**
 *
 * @param portfolioID
 * @return {Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>}
 */
const getPortfolio = (portfolioID) =>
  firebase
    .firestore()
    .collection('portfolios')
    .doc(portfolioID)
    .get()
    .then((doc) => {
      if (doc.exists) return { id: doc.id, ...doc.data() };
      else throw new Error("Portfolio Doesn't exist");
    });

/**
 *
 * @param {string|Object} portfolio
 * @throws {Error}
 * @return {Promise<Array<{opportunityID, opportunityName}>>}
 */
const getPortfolioOpportunities = async (portfolio) => {
  if (typeof portfolio === 'string') portfolio = await getPortfolio(portfolio);
  if (portfolio.type === 'Fixed') {
    return portfolio.opportunities || [];
  } else if (portfolio.type === 'Dynamic') {
    const firestore = firebase.firestore();
    if (!Array.isArray(portfolio.criteria) || !portfolio.criteria.length) throw new Error('Must have at least one criteria.');

    const criterias = portfolio.criteria.map(({ field, operator, value }) => ({
      field,
      operator,
      value: FirebaseJSONOperators.includes(operator) ? JSON.parse(value) : value,
    }));

    const regularQueries = criterias.filter((criteria) => !FirebaseJSONOperators.includes(criteria.operator));
    const arrayBasedQueries = criterias.filter((criteria) => FirebaseJSONOperators.includes(criteria.operator));

    const notINCriteria = arrayBasedQueries.filter((criteria) => criteria.operator === 'not-in');
    const containAnyOrINCriteria = arrayBasedQueries
      .filter((criteria) => criteria.operator !== 'not-in')
      .sort((a, b) => b.value.length - a.value.length);

    const complexCriteria = containAnyOrINCriteria.map(({ field, operator, value }, index) =>
      index === 0 && !notINCriteria.length
        ? chunk(value, 10).map((chunk) => ({
            field,
            operator,
            value: chunk,
          }))
        : value.map((sv) => ({
            field,
            operator: operator === 'in' ? '==' : 'array-contains',
            value: sv,
          })),
    );
    // const arrayBasedSmallQueries = containAnyOrINCriteria.filter(criteria => (criteria.value.length <= 10));
    // const arrayBasedBigQueries = containAnyOrINCriteria.filter(criteria => (criteria.value.length > 10));
    // // we break each condition into chunks of conditions
    // const extendedInQueries = arrayBasedBigQueries
    //   .map(({field, operator, value}) => _chunk(value, 10).map(chunk => ({field, operator, value:chunk})));

    const opportunitiesRef = firestore.collection('opportunities');
    const opportunitiesRefSimpleQuery = regularQueries.reduce(
      (c, criteria) => c.where(criteria.field, criteria.operator, criteria.value),
      opportunitiesRef,
    );
    // const opportunitiesRefSimpleWzNotInQuery = notINCriteria.map(({field, _, value}) => value.map(v => ({field, operator: '!=', value: v}))).flat()
    //   .reduce((c, criteria) => c.where(criteria.field, criteria.operator, criteria.value), opportunitiesRefSimpleQuery);
    /** @throws {FirebaseError} if more than one not in */
    const opportunitiesRefSimpleWzNotInQuery = notINCriteria.reduce(
      (c, criteria) => c.where(criteria.field, criteria.operator, criteria.value),
      opportunitiesRefSimpleQuery,
    );
    /*
     * Cases for queries:
     * Simple only:                       Done directly using opportunitiesRefSimpleQuery                          (CrossProduct wz [[]])
     * Simple and only one small:         Chain opportunitiesRefSimpleQuery with the small                         (CrossProduct wz [small1])
     * Simple and only one big:           opportunitiesRefSimpleQuery with chained with CrossProduct big chunked   (CrossProduct wz big1)
     * Simple and one small and one big:  Convert small to multiple simple queries and use in cross product        (CrossProduct wz [small1] wz big1)
     * the rest would contain multiple of each: Keep the largest as old operator and convert the rest to normal simple cross product
     *
     * Note: ~not-in can be converted to multiple `!=`~ turns out you can't have multiple != and not documented on the site
     * -----------
     * Convert all to simple ones except for the largest split in chunks then CrossProduct
     * */

    /** @var {Array<firebase.firestore.QuerySnapshot>} */
    const opportunitiesQueriesSnapshot = await Promise.all(
      cartesian(...complexCriteria, [[]]) //get all possible combination of conditions, [[]] is used to force proper structure even in case of one element
        .map((product) =>
          product
            .reduce(
              // each product will have one or more conditions
              (c, pCriteria) => c.where(pCriteria.field, pCriteria.operator, pCriteria.value),
              opportunitiesRefSimpleWzNotInQuery,
            )
            .get(),
        ),
    );

    // filtering is only needed for the `array-contains-any` operator
    const oppties = opportunitiesQueriesSnapshot
      .map((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({
          opportunityID: doc.id,
          opportunityName: doc.get('opportunityName'),
        })),
      )
      .flat();
    return [...new Map(oppties.map((item) => [item.opportunityID, item])).values()];
  } else throw new Error('Unsupported Portfolio Type');
};

export { FirebaseJSONOperators, getPortfolio, getPortfolioOpportunities };
