<template lang="pug">
  v-navigation-drawer.lightedison__sidebar(app v-model='drawer')
    router-link(to='/home' tag='a')
      v-app-bar(elevation='1' color='terciary')
        v-list-item
          v-img(width='200' :src='lightedison_logo')
    v-list(v-if='!$vuetify.breakpoint.smAndUp')
      v-list-item
        v-list-item-avatar
          v-img(:src="user.photoURL || 'https://firebasestorage.googleapis.com/v0/b/lightedison-devserver.appspot.com/o/0.png?alt=media&token=ae12ac21-5b38-4293-847e-95b67889a30f'")
      v-menu(open-on-hover offset-y max-width='180' nudge-right='65')
        template(v-slot:activator='{ on, attrs }')
          v-list-item(v-bind='attrs' v-on='on')
            v-list-item-content
              v-list-item-title.title
                | {{ user.displayName }}
              v-list-item-subtitle {{ user.email }}
            v-list-item-action
              v-icon mdi-menu-down
        v-list
          v-list-item(link @click.stop="$emit('profile', user)")
            v-list-item-title Profile
          v-list-item(link @click.stop="$emit('password')")
            v-list-item-title Change Password
          v-divider
          v-list-item(link @click.stop="$emit('logout')")
            v-list-item-title Logout
    v-divider(v-if='!$vuetify.breakpoint.smAndUp')
    v-list(nav dense)
      template(v-for='item in items')
        component(:is='item.link ? "router-link" : "fragment"' :to='item.link' :key='item.title')
          v-list-group(v-if='item.items && item.items.length' :key='item.title' v-model='item.active' no-action)
            template(v-slot:activator)
              v-list-item-content
                v-list-item-title {{ item.title }}
            template(#prependIcon)
              v-icon {{ item.icon }}
            template(#appendIcon)
              v-list-item-action.ma-0(@click.prevent)
                v-btn(icon)
                  v-icon(color='grey lighten-1') mdi-chevron-down
            template(v-for='child in item.items')
              v-list-item(v-if='!child.items' :key='child.title' :to='child.link' link exact)
                v-list-item-content
                  v-list-item-title(:title="child.title") {{ child.title }}
              template(v-else)
                component(:is='child.link ? "router-link" : "fragment"' :to='child.link' :key='child.title')
                  v-list-group(:key='child.title' no-action sub-group)
                    template(v-slot:activator)
                      v-list-item-content
                        v-list-item-title(:title="child.title") {{ child.title }}
                    template(#appendIcon v-if="child.link && child.items.length")
                      v-list-item-action.ma-0(@click.prevent)
                        v-btn(icon)
                          v-icon(color='grey lighten-1') mdi-chevron-down
                    //v-list-item(v-for='nestedChild in child.items' :key='nestedChild.title' :to='nestedChild.link' link exact)
                    //  v-list-item-title
                    //    | {{ nestedChild.title }}
                    //  v-list-item-icon
                    //    v-icon {{ nestedChild.icon }}
                    template(v-for='nestedChild in child.items')
                      v-list-item(v-if='!nestedChild.items' :key='nestedChild.title' :to='nestedChild.link' link exact)
                        v-list-item-content
                          v-list-item-title(:title="nestedChild.title") {{ nestedChild.title }}
                      template(v-else)
                        component(:is='nestedChild.link ? "router-link" : "fragment"' :to='nestedChild.link' :key='nestedChild.title')
                          v-list-group(:key='nestedChild.title' no-action sub-group)
                            template(v-slot:activator)
                              v-list-item-content
                                v-list-item-title(:title="nestedChild.title") {{ nestedChild.title }}
                            v-list-item(v-for='grandChild in nestedChild.items' :key='grandChild.title' :to='grandChild.link' link exact)
                              v-list-item-title(:title="grandChild.title")
                                | {{ grandChild.title }}
                              v-list-item-icon(v-if="grandChild.icon")
                                v-icon {{ grandChild.icon }}
          v-list-item(v-else-if='item.title && item.link' :key='item.title' :to='item.link' exact)
            v-list-item-icon
              v-icon {{ item.icon }}
            v-list-item-title {{ item.title }}
</template>

<script lang="ts">
import lightedison_logo from '@/assets/lightedison.png';
import { SuperAdminRole } from '@/config/variables';
import { items } from '@/pages/operations/human_resources/Healthcare/HealthcareBenefitsHelper';
import UserService from '@/services/data/userService';
import { getRecords } from '@/services/firebase/FirestoreService';
import firebase from 'firebase';
import { Fragment } from 'vue-frag';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: { Fragment },
  data() {
    return {
      navigationDrawer: this.drawer,
      user: {
        displayName: null,
        phoneNumber: null,
        photoURL: null,
        email: null,
        role: null,
        routeAccess: [],
        resourceAccess: {},
      },
      lightedison_logo: lightedison_logo,
      schemas: null,
    };
  },
  watch: {
    role(value) {
      //
      if (!value) {
        return;
      }

      UserService.getUserRole()
        .then((role) => {
          this.user.routeAccess = role.routeAccess;
          this.user.resourceAccess = role.resourceAccess;

          if (this.user.resourceAccess?.schemas?.includes('read')) {
            getRecords({
              collectionName: 'schemas',
            }).then((schemas) => {
              this.schemas = schemas;
            });
          }
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            error,
          };
          this.addNotification(errorObject);
        });
    },
  },
  computed: {
    ...mapGetters('utils', ['getDrawer', 'getAllRecords', 'querySnapshotToArray']),
    ...mapGetters('opportunities', ['opportunities']),
    role() {
      return this.user.role;
    },
    drawer: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.toggleDrawer(value);
      },
    },
    items() {
      if (!this.user.role) {
        return;
      }

      // For Lighting Review
      const mappedRecentOpportunities = this.recentOpportunities.map((opportunity) => ({
        link: `/spaces/${opportunity.opportunityRecordID}`,
        title: opportunity.opportunityName,
      }));

      // For Product Summary
      const mappedRecentOpportunitiesProductSummary = this.recentOpportunities.map((opportunity) => ({
        link: `/product_summary/${opportunity.opportunityRecordID}`,
        title: opportunity.opportunityName,
      }));

      // For Mapping
      const mappedRecentOpportunitiesMapping = this.recentOpportunitiesWithInstallations.map((opportunity) => ({
        link: `/installationMap/${opportunity.opportunityRecordID}`,
        title: opportunity.opportunityName,
      }));

      // For Developments
      // const mappedRecentOpportunitiesDevelopment =
      //   this.recentOpportunitiesWithInstallations.map(opportunity => ({
      //     link: {
      //       name: "DevelopmentRecord",
      //       params: { opportunityID: opportunity.opportunityRecordID },
      //     },
      //     title: opportunity.opportunityName,
      //   }));

      // For ECMS
      const mappedRecentOpportunitiesECMS = this.recentOpportunities.map((opportunity) => ({
        link: `/designs/ecms/${opportunity.opportunityRecordID}`,
        title: opportunity.opportunityName,
      }));

      // For Designs
      // const mappedRecentOpportunitiesDesigns = this.recentOpportunities.map(
      //   opportunity => ({
      //     link: `/designs/${opportunity.opportunityRecordID}`,
      //     title: opportunity.opportunityName,
      //   })
      // );

      // For Buildings section
      const buildingOpportunities = this.recentOpportunities.filter((opportunity) => {
        const opportunityType = opportunity.opportunityType;
        return opportunityType === 'Building: Non-IGA' || opportunityType === 'Building: IGA';
      });

      const mappedBuildingOpportunities = buildingOpportunities.map((opportunity) => {
        return {
          link: `/buildings/${opportunity.opportunityRecordID}`,
          title: opportunity.opportunityName,
        };
      });

      const customObjects = (this.schemas || []).map((schema) => ({
        title: schema.pluralName,
        link: {
          name: 'CustomObjectList',
          params: { schemaID: schema.id },
        },
      }));

      const procurement = {
        title: 'Procurement',
        icon: 'mdi-file-document-edit-outline',
        items: [
          {
            title: 'Products',
            items: [
              {
                title: 'Primary Products',
                link: '/primary_products',
              },
              {
                title: 'Control Products',
                link: '/control_products',
              },
              {
                title: 'Accessory Products',
                link: '/accessory_products',
              },
              {
                title: 'Solar Products',
                link: '/solar_products',
              },
            ],
          },
          {
            title: 'Product Summary',
            items: mappedRecentOpportunitiesProductSummary,
            link: '/opportunities?where=product_summary',
          },
          {
            title: 'Purchase Orders',
            link: '/purchaseOrders',
          },
          {
            title: 'Shipments',
            link: '/shipments',
          },
          {
            title: 'PO Request',
            href: 'https://docs.google.com/forms/d/e/1FAIpQLSeHdIG_TfJ6zx6CsnVSoGklRTUt7zPf5ZuskAdhHsSr_4u1RQ/viewform',
            target: '_blank',
          },
          {
            title: 'Auxiliary Equip Request',
            href: 'https://docs.google.com/forms/d/e/1FAIpQLScyy6GvIHx3n3Hpahn1P67aAUdJfvclpd4N_Gd6zHrjx-ER4g/viewform',
            target: '_blank',
          },
          {
            title: 'Delivery Receipt Form',
            href: 'https://docs.google.com/forms/d/e/1FAIpQLSeCMQBB3Tk9zmF65KtejO17HtNWOrK2pYxo_Fm7Uy6JDYGtAQ/viewform',
            target: '_blank',
          },
        ],
      };
      const mapping = {
        title: 'Mapping',
        icon: 'mdi-google-maps',
        items: mappedRecentOpportunitiesMapping,
        link: '/opportunities?where=installations&type=maps',
      };
      const navigationItems = [
        {
          title: 'Continuous Improvement',
          link: '/continuous_improvement',
          icon: 'mdi-cog-refresh-outline',
        },
        {
          title: 'CRM',
          icon: 'mdi-handshake',
          items: [
            {
              title: 'Accounts',
              link: '/accounts',
            },
            {
              title: 'Contacts',
              link: '/contacts',
            },
            {
              title: 'Facilities',
              link: '/facilities',
            },
            {
              title: 'Opportunities',
              link: '/opportunities-salesforce',
            },
            {
              title: 'Designs',
              link: '/designs-salesforce',
            },
            {
              title: 'Service',
              link: '/services',
            },
            {
              title: 'Pre-C',
              link: '/pre-contracting',
            },
            {
              title: 'Contracted',
              link: '/contracted',
            },
            {
              title: 'Accounting',
              link: '/accounting',
            },
            {
              title: 'Project Engines',
              link: '/project-engines',
            },
            {
              title: 'Proposal Requests',
              link: '/proposal-requests',
            },
            {
              title: 'Work Orders',
              link: '/work-orders',
            },
            {
              title: 'Reports',
              link: '/reports',
            },
            {
              title: 'Returns',
              link: '/returns',
            },
            {
              icon: 'mdi-folder-multiple',
              title: 'Opportunity AIO',
              link: '/opportunities?where=oc',
            },
            {
              title: 'Schemas',
              items: [
                {
                  title: 'New Template Schema',
                  link: { name: 'TemplateSchemaAdd' },
                },
                {
                  title: 'Template Schemas',
                  link: { name: 'TemplateSchemaList' },
                },
                {
                  title: 'New Schema',
                  link: { name: 'SchemaAdd' },
                },
                {
                  title: 'Schemas List',
                  link: { name: 'SchemaList' },
                },
              ],
            },
            {
              title: 'Custom Objects',
              items: customObjects,
            },
            {
              title: 'Picklists',
              link: { name: 'PicklistList' },
            },
            {
              title: 'Tasks',
              items: [
                {
                  title: 'New Task',
                  link: { name: 'TaskAdd' },
                },
              ],
            },
          ],
        },
        {
          title: 'Lighting',
          icon: 'mdi-lightbulb-group-outline',
          items: [
            {
              title: 'Quick Codes',
              icon: 'mdi-book',
              items: [
                { title: 'Active QCs', link: '/quick_codes' },
                {
                  title: 'Archived QCs',
                  link: '/quick_codes_archived',
                },
              ],
            },
            {
              title: 'Site Reviews',
              icon: 'mdi-home-search',
              items: [
                {
                  title: 'Lighting Site Reviews',
                  link: '/opportunities?where=lr',
                  items: mappedRecentOpportunities,
                },
                {
                  title: 'Solar Site Reviews',
                  link: '/opportunities?where=lr',
                  items: mappedRecentOpportunities,
                },
                {
                  title: 'Reparent Spaces',
                  link: { name: 'ReparentSpaces' },
                  deniedAccess:
                    ['mc@lightedison.com', 'rizk@lightedison.com'].indexOf(firebase.auth().currentUser.email.toLowerCase()) === -1,
                },
              ].filter(({ deniedAccess }) => !deniedAccess),
            },
            {
              title: 'ECMs',
              icon: 'mdi-chart-gantt',
              items: mappedRecentOpportunitiesECMS,
              link: '/opportunities?where=ecms',
            },
            {
              title: 'Buildings',
              icon: 'mdi-office-building-outline',
              items: mappedBuildingOpportunities,
              link: '/opportunities?where=buildings',
            },
            procurement,
            mapping,
          ],
        },
        {
          title: 'Finance',
          icon: 'mdi-briefcase-variant',
          items: [
            {
              title: 'Project Finance',
              icon: 'mdi-finance',
              link: '/opportunities?where=pf',
              items: this.recentOpportunities.map((opportunity) => ({
                link: {
                  name: 'ProjectFinancePicker',
                  params: {
                    opportunityID: opportunity.opportunityRecordID,
                  },
                },
                title: opportunity.opportunityName,
              })),
            },
            {
              title: 'Job Profitability',
              link: '/opportunities?where=jp',
            },
            {
              title: 'Job Profitability By Month',
              link: '/opportunities?where=jpbm',
            },
            {
              title: 'Change Orders',
              link: '/change_orders',
            },
            {
              title: 'Time/Expense Entries',
              link: '/time_entries_expenses',
            },
            {
              title: 'Timesheets',
              link: '/timesheets',
            },
            {
              title: 'Opportunity Time Dashboard',
              link: '/opportunity_time_dashboard',

              deniedAccess:
                [
                  // ELT
                  'mc@lightedison.com',
                  'on@lightedison.com',
                  'sms@lightedison.com',
                  'at@lightedison.com',
                  // Dev Team
                  'rizk@lightedison.com',
                ].indexOf(firebase.auth().currentUser.email.toLowerCase()) === -1,
            },
            {
              title: 'Labor Costing',
              link: '/labor-costing',
            },
            {
              title: 'AP Records',
              items: [
                {
                  title: 'New AP Record',
                  link: { name: 'NewAPRecord' },
                },
                {
                  title: 'All AP Records List',
                  link: { name: 'APRecordsList' },
                },
                {
                  title: 'Recent AP Records List',
                  link: { name: 'RecentAPRecordsList' },
                },
              ],
            },
            {
              title: 'Leave Requests',
              items: [
                {
                  title: 'New Leave Request',
                  link: '/vacation-requests/new',
                },
                {
                  title: 'Submitted Leave requests',
                  link: '/vacation-requests',
                },
              ],
            },
          ].filter(({ deniedAccess }) => !deniedAccess),
        },
        {
          title: 'Services',
          icon: 'mdi-tools',
          items: [
            {
              title: 'Project Record',
              link: '/opportunities?where=project_record',
            },
            {
              title: 'Project Charter',
              icon: 'mdi-developer-board',
              items: [
                {
                  title: 'Opportunity Picker',
                  link: '/opportunities?where=dev',
                },
                {
                  title: 'Template',
                  link: { name: 'DevelopmentTemplate' },
                },
              ],
            },
            procurement,
            mapping,
            {
              title: 'PM Verification',
              link: '/pm_verification',
              icon: 'mdi-clipboard-text-search-outline',
            },
            {
              title: 'Construction',
              icon: 'mdi-cog-outline',
              items: [
                {
                  title: 'Daily Reports',
                  items: [
                    {
                      title: 'New Daily Report',
                      link: '/work_reports/new',
                    },
                    {
                      title: 'Submitted Daily Reports',
                      link: '/work_reports',
                    },
                  ],
                },
                {
                  title: 'Reporting',
                  items: [
                    {
                      title: 'New RFI',
                      link: '/rfi_reports/new',
                    },
                    {
                      title: 'New Issue',
                      link: '/issues_reports/new',
                    },
                    {
                      title: 'New Punch List',
                      link: '/punch_list_reports/new',
                    },
                    {
                      title: 'Submitted Reports',
                      link: '/submitted_reports',
                    },
                    {
                      title: 'Install Reports',
                      link: '/lighting_reports',
                    },
                  ],
                },
                {
                  title: 'Weekly Overviews',
                  items: [
                    {
                      title: 'New Weekly Overview',
                      link: '/weekly-overviews/new',
                    },
                    {
                      title: 'Submitted Weekly Overviews',
                      link: '/weekly-overviews',
                    },
                  ],
                },
                {
                  title: 'Submittals',
                  link: {
                    name: 'Opportunities',
                    query: { where: 'submittals' },
                  },
                },
                {
                  title: 'Checklists',
                  link: '/checklists',
                },
                {
                  title: 'Construction Evaluations',
                  link: '/construction-evaluations',
                },
                {
                  title: 'PV System Test Reports',
                  link: '/pv-system-test-reports',
                },
                {
                  title: 'PV System Commisioning Reports',
                  link: '/pv-system-commissioning-reports',
                },
                {
                  title: 'Vehicles',
                  link: '/vehicles',
                },
                {
                  title: 'Equipments',
                  link: '/equipments',
                },
                {
                  title: 'Equipment Inspections',
                  link: '/equipment-inspections',
                },
              ],
            },
          ],
        },
        {
          title: 'Scheduling',
          icon: 'mdi-timetable',
          items: [
            {
              title: 'ClickUP',
              link: '/scheduling',
            },
            {
              title: 'Opportunities',
              items: [
                {
                  title: 'Opportunity Picker',
                  link: '/opportunities?where=scheduling',
                },
                {
                  title: 'Templates',
                  link: { name: 'SchedulingTemplateList' },
                },
              ],
            },
            {
              title: 'Corporate',
              link: { name: 'CorpScheduleList' },
            },
          ],
        },
        {
          title: 'Portfolios',
          link: { name: 'PortfolioList' },
          icon: 'mdi-notebook-multiple',
        },
        // {
        //   title: "Solar Estimate",
        //   icon: "mdi-solar-panel",
        //   items: [
        //     {
        //       title: "Opportunity Picker",
        //       link: "/opportunities?where=se",
        //     },
        //     {
        //       title: "Template",
        //       link: { name: "SolarEstimateTemplate" },
        //     },
        //   ],
        // },
        // Taken Out for now
        // {
        //     title: 'Designs',
        //     icon: 'mdi-pencil',
        //     items: mappedRecentOpportunitiesDesigns,
        //     link: '/opportunities?where=designs'
        // },
        {
          title: 'Operations',
          icon: 'mdi-chart-box-plus-outline',
          items: [
            {
              title: 'Folder Request Form',
              link: { name: 'ProjectShareFolder' },
            },
            {
              title: 'Flight Requests',
              link: '/flight_requests',
            },
            {
              title: 'Lodging Request Form',
              link: '/gform/LodgingRequestForm',
            },
            {
              title: 'Tool Logs',
              link: '/tool_logs',
            },
            {
              title: 'Employee Apparel',
              link: '/employee-apparel',
            },
            {
              title: 'LightEdison Wiki',
              link: '/lightedison_wiki',
            },
            {
              title: 'SOPs & Policies',
              link: '/sops',
            },
            {
              title: 'Talent',
              icon: 'mdi-account-group-outline',
              items: [
                {
                  title: 'Checklists',
                  items: [
                    {
                      title: 'Records',
                      link: '/talent_checklists/records',
                    },
                    {
                      title: 'Templates',
                      link: '/talent_checklists/templates',
                    },
                    {
                      title: 'My Tasks',
                      link: '/talent_checklists/my_tasks',
                    },
                  ],
                },
                {
                  title: 'Position Agreements',
                  link: '/position-agreements',
                },
                {
                  title: 'Payroll Deduction Authorization',
                  link: {
                    name: 'PayrollDeductionAuthorizationList',
                  },
                },
                {
                  title: 'Employee Handbook',
                  link: '/employee_handbook',
                },
                {
                  title: '2024 Benefits',
                  items: Object.entries(items[2024]).map(([key, item]) => ({
                    title: item.title,
                    link: item.disabled
                      ? false
                      : {
                          name: 'HealthcareBenefits',
                          params: {
                            itemID: key,
                            year: 2024,
                          },
                        },
                  })),
                },
                {
                  title: '2023 Benefits',
                  items: Object.entries(items[2023]).map(([key, item]) => ({
                    title: item.title,
                    link: item.disabled
                      ? false
                      : {
                          name: 'HealthcareBenefits',
                          params: {
                            itemID: key,
                            year: 2023,
                          },
                        },
                  })),
                },
                {
                  title: 'DOL Notifications',
                  link: '/department_of_labor_notifications',
                },
                {
                  title: '2023 OSHA 300',
                  link: '/osha_form',
                },
                {
                  title: 'Termination Records',
                  link: '/termination_records',
                },
                {
                  title: 'Quarterly Evaluations',
                  items: [
                    {
                      title: 'New Quarterly Evaluation',
                      link: '/quarterly_evaluations/new',
                    },
                    {
                      title: 'Quarterly Evaluations',
                      link: '/quarterly_evaluations',
                    },
                    {
                      title: 'Employee Report',
                      link: '/quarterly_evaluations/quarterly_employee_report',
                    },
                  ],
                },
              ].filter(({ deniedAccess }) => !deniedAccess),
            },
            {
              title: 'OrgChart',
              link: '/orgchart',
              icon: 'mdi-family-tree',
            },
            {
              title: 'Mail Groups',
              link: '/mailgroups',
              icon: 'mdi-account-group',
            },
            {
              title: 'Compliance',
              items: [
                {
                  title: 'RAP Request Tracker',
                  items: [
                    {
                      title: 'New RapCART',
                      link: {
                        name: 'ApprenticeRequestTrackerAdd',
                      },
                    },
                    {
                      title: 'RapCART List',
                      link: {
                        name: 'ApprenticeRequestTrackerList',
                      },
                    },
                  ],
                },
                {
                  title: 'Certified Payroll',
                  link: { name: 'CertifiedPayrollList' },
                },
                {
                  title: 'Certificate of Insurance',
                  link: {
                    name: 'CertificateOfInsuranceList',
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Admin Portal',
          items: [
            {
              title: 'Users',
              link: '/users',
            },
            {
              title: 'Permissions',
              link: '/permissions',
            },
            {
              title: 'Roles',
              link: '/roles',
            },
          ],
          icon: 'mdi-account-edit',
        },
      ].filter(({ deniedAccess }) => !deniedAccess);

      const sidebarRoleAccessReducer =
        (role, routeAccess = []) =>
        (acc, item) => {
          //
          const hasAccessToUrl = (routeAccess, config) => {
            return role === SuperAdminRole || this.$router.resolve(config).route.matched.every(({ path }) => routeAccess.includes(path));
          };

          // Modifies a sidebar item based on the
          // routeAccess property of the signed in user's role (role property on user, which is a reference to the roles collection)
          const getFilteredItem = (item) => {
            const getConfig = (link) => {
              if (typeof item.link == 'string') {
                return { path: link };
              } else if (item.link && typeof item.link == 'object' && !Array.isArray(item.link)) {
                return link;
              }
            };

            // Filtering sub-items before sidebar item evaluation
            const items = item.items && item.items.map(getFilteredItem).filter((item) => item);

            return (item.link && hasAccessToUrl(routeAccess, getConfig(item.link))) || item.href
              ? {
                  ...item,
                  ...(items && items.length ? { items } : {}),
                }
              : items && items.length
                ? {
                    ...item,
                    items,
                  }
                : null;
          };

          //
          return [...acc, getFilteredItem(item)].filter((item) => item);
        };

      return navigationItems.reduce(sidebarRoleAccessReducer(this.user.role, this.user.routeAccess || []), []);
    },
    recentOpportunities() {
      const opportunities = this.opportunities.filter(({ stage }) => stage !== 'Closed Lost');

      return this.sortAndLimitOpportunities(opportunities);
    },
    recentOpportunitiesWithInstallations() {
      const installationRelatedOpportunityStages = [
        'Design',
        'Proposal',
        'Pre-Contracting Review',
        'Rebate Approvals',
        'Contracting',
        'Long Term Pre-C',
        'Procurement',
        'Installation',
        'Substantial Completion',
        'Final Completion',
        'Close Out',
        'Service',
      ];
      const opportunitiesWithInstallationStages = (this.opportunities || []).filter(({ stage }) =>
        installationRelatedOpportunityStages.includes(stage),
      );
      return this.sortAndLimitOpportunities(opportunitiesWithInstallationStages);
    },
  },
  async mounted() {
    const user = UserService.getCurrentUser();

    this.user.displayName = user.displayName;
    this.user.phoneNumber = user.phoneNumber;
    this.user.photoURL = user.photoURL;
    this.user.email = user.email;
    this.user.uid = user.uid;

    try {
      this.user.role = await UserService.getRoleId();

      if (this.user.role) {
        this.fetchOpportunities();
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapMutations('utils', ['toggleDrawer']),
    ...mapActions('opportunities', ['fetchOpportunities']),
    ...mapActions('notifications', ['addNotification']),

    sortAndLimitOpportunities(items) {
      const sorter = (a, b) => {
        if (a.lastViewedDate && b.lastViewedDate) {
          return new Date(b.lastViewedDate).getTime() - new Date(a.lastViewedDate).getTime();
        } else {
          if (b.lastViewedDate) return 1;
          if (a.lastViewedDate) return -1;

          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
        }
      };

      const newItems = JSON.parse(JSON.stringify(items));
      newItems.sort(sorter);
      return newItems.slice(0, 10);
    },
  },
};
</script>

<style>
.lightedison__sidebar {
  z-index: 100 !important;
}
.lightedison__sidebar .v-list-item__icon:first-child {
  margin-right: 20px !important;
}
</style>
<style scoped lang="scss">
>>> .v-list-group--no-action.v-list-group--sub-group .v-list-group--sub-group .v-list-group__header {
  padding-left: 36px !important;
}
>>> .v-list-group--no-action.v-list-group--sub-group .v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 100px !important;
}
>>> .v-list-item__title {
  white-space: normal;
}
</style>
