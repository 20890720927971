<template>
  <v-footer padless color="transparent">
    <v-col class="text-center" cols="12">
      <strong>LightEdison, Inc</strong> &copy;
      {{ new Date().getFullYear() }}
    </v-col>
  </v-footer>
</template>

<script lang="ts">
export default {
  name: 'AppFooter',
};
</script>

<style scoped></style>
