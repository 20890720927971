import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import LeaveRequestRepository from '@/core/features/leave-requests/interfaces/LeaveRequestRepository';
import ContactsRepository from '@/core/features/contacts/interfaces/ContactsRepository';

// TODO: Think about a better way to handle this
import { SuperAdminRole, HeatherLloydUserID, HeatherSolleyUserID } from '@/config/variables';

interface UseCaseDependencies {
  leaveRequestRepository: LeaveRequestRepository;
  authenticationGateway: AuthenticationGateway;
  contactsRepository: ContactsRepository;
}

interface DateRange {
  from: string;
  to: string;
}

export default class GetLeaveRequests {
  leaveRequestRepository: LeaveRequestRepository;
  contactsRepository: ContactsRepository;
  authenticationGateway: AuthenticationGateway;

  constructor(dependencies: UseCaseDependencies) {
    this.leaveRequestRepository = dependencies.leaveRequestRepository;
    this.contactsRepository = dependencies.contactsRepository;
    this.authenticationGateway = dependencies.authenticationGateway;
  }

  async execute({ from, to }: DateRange) {
    const currentUser = await this.authenticationGateway.getCurrentUser();
    if (!currentUser) {
      throw new Error('Unauthorized Access');
    }

    const fromTime = new Date(from as any).getTime();
    const toTime = new Date(to as any).getTime();

    if (!from || !to) {
      throw new Error('Invalid Date Range');
    }

    if (toTime < fromTime) {
      throw new Error('Invalid Date Range');
    }

    const { role, contactRecord, user_id: uid } = await this.authenticationGateway.getCustomClaimsFromCurrentUser();
    const isSuperAdmin = role == SuperAdminRole;
    const isAllowedUser = [HeatherLloydUserID, HeatherSolleyUserID].includes(uid);
    if (!role) {
      throw new Error('Unauthorized Access');
    }

    const dateRangeFilters = [
      { field: 'createdDate', operator: '>=', value: from },
      { field: 'createdDate', operator: '<=', value: to },
    ];

    if (isSuperAdmin || isAllowedUser) {
      return this.leaveRequestRepository.getLeaveRequests({
        filters: dateRangeFilters,
      });
    } else if (contactRecord) {
      // Subordinate Requests
      const subordinates = await this.contactsRepository.getSubordinates({
        contactId: contactRecord,
      });
      const subordinateIds = subordinates.map((subordinate) => subordinate.id);

      let subordinatesRequests = [];
      if (subordinateIds.length) {
        const subordinateFilters = [
          ...dateRangeFilters,
          {
            field: 'employee',
            operator: 'in',
            value: subordinateIds,
          },
        ];

        subordinatesRequests = await this.leaveRequestRepository.getLeaveRequests({
          filters: subordinateFilters,
        });
      }

      // User Requests
      const currentUserFilters = [...dateRangeFilters, { field: 'employee', operator: '==', value: contactRecord }];
      const currentUserRequests = await this.leaveRequestRepository.getLeaveRequests({
        filters: currentUserFilters,
      });

      return [...subordinatesRequests, ...currentUserRequests];
    } else {
      throw new Error('Unauthorized Access');
    }
  }
}
