import { saveRecord } from '@/services/firebase/FirestoreService.js';
import { firestoreDocument, querySnapshotToArray } from '@/services/utils/Utils.js';
import firebase from 'firebase';

export const namespaced = true;

export const state = {
  weeklyOverviews: [],
  subscription: null,
};

export const getters = {
  weeklyOverviews: ({ weeklyOverviews }) => weeklyOverviews,
  weeklyOverview: (state) => (id) => state.weeklyOverviews.find((weeklyOverview) => weeklyOverview.id === id),
};

export const mutations = {
  SET_WEEKLY_OVERVIEWS(state, weeklyOverviews) {
    state.weeklyOverviews = weeklyOverviews;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchWeeklyOverviews({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('weeklyOverviews');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_WEEKLY_OVERVIEWS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  async saveWeeklyOverview(_context, weeklyOverviewOptions) {
    return saveRecord(weeklyOverviewOptions).then((id) => id);
  },
  fetchWeeklyOverview({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.weeklyOverview(id)) {
        resolve(getters.weeklyOverview(id));
        return;
      }

      const weeklyOverviewDocument = firestoreDocument('weeklyOverviews')(id);
      weeklyOverviewDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
