import HolidaysRepository from '@/core/features/leave-requests/interfaces/HolidaysRepository';

export default class GetLeaveRange {
  holidaysRepository: HolidaysRepository;
  constructor({ holidaysRepository }) {
    this.holidaysRepository = holidaysRepository;
  }

  execute({ from, to }: { from: string; to: string }) {
    if (!from || !to) {
      throw new Error('Invalid Date Range');
    }

    const fromDate = new Date(from);
    const toDate = new Date(to);
    if (fromDate > toDate) {
      throw new Error('Invalid Date Range');
    }

    const dateDifference = toDate.getTime() - fromDate.getTime();
    const daysDifference = Math.floor(dateDifference / (1000 * 3600 * 24));
    const days = [];

    for (let i = 0; i <= daysDifference; i++) {
      const date = new Date(fromDate);
      date.setTime(date.getTime() + i * 1000 * 3600 * 24);
      const dateString = date.toISOString().split('T')[0];

      const holidayEvent = this.holidaysRepository.getHolidayInstance({
        date: dateString,
      });
      const isValidHoliday = !!(holidayEvent && holidayEvent.date && holidayEvent.name);
      const isWeekend = date.getUTCDay() == 6 || date.getUTCDay() == 0;

      days.push({
        date: dateString,
        isHoliday: isValidHoliday,
        ...(isValidHoliday ? { holidayName: holidayEvent.name } : {}),
        isWeekend: isWeekend,
      });
    }

    return days;
  }
}
