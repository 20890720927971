import { opportunityBasedUnit, recursiveCalculateEstimate, templateAsFlatObject } from '@/components/projectFinance/ProjectFinanceHelper';
import router from '@/router';
import { getFirestorePicklist } from '@/services/pickers/PickersService';
import { systemFields } from '@/services/utils/Utils';
import firebase from 'firebase';
import _escape from 'lodash/escape.js';
import moment from 'moment';
import Vue from 'vue';

const namespaced = true;

const collapsedTemplate = {
  estimationGroup: false,

  cost: true,
  labor: true,
  tax: true,
  contingency: true,
  netCost: true,
  finance: true,
  netLoadedCost: true,
  profit: true,
  finalEstimatedPrice: true,
  info: true,
  estimateSystem: true,
};
const filtersTemplate = {
  search: '',
  estimator: [],
  estimationStatus: [],
};

const state = {
  opportunityID: null,
  group: null,
  version: null,
  opportunity: null,
  facility: null,
  template: {},
  collapsed: { ...collapsedTemplate },
  projectFinance: null,
  projectFinanceDetails: {},
  rateTable: null,
  subs: [],
  showChildrenInSearch: false,
  showOnlyNonEmpty: false,
  estimatingStatuses: [],
  unitsOfMeasure: [],
  filters: { ...filtersTemplate },

  sopMapping: null,
  sops: {},
};

const getters = {
  templateAsFlatObject: ({ template }) => templateAsFlatObject(template),
  opportunityBasedUnit: ({ opportunity }) => opportunityBasedUnit(opportunity?.opportunityType),
  sops: ({ sops }) => Object.values(sops),
  snapshotCalculation: ({ template, projectFinanceDetails, projectFinance, rateTable }) => {
    const obj = {};
    recursiveCalculateEstimate(obj, template, undefined, projectFinanceDetails, projectFinance, rateTable?.items);
    return obj;
  },
};

const mutations = {
  unsub(state) {
    const subs = state.subs;
    state.subs = [];
    subs.forEach((unsub) => {
      if (unsub && typeof unsub === 'function') unsub();
    });
  },
  clearTemplate(state) {
    state.template = {};
  },
  clearRecord(state) {
    state.projectFinance = null;
    state.projectFinanceDetails = {};
    state.sopMapping = null;
    state.rateTable = null;
    state.opportunity = null;
    state.facility = null;
  },
  resetFilters(state) {
    Vue.set(state, 'filters', { ...filtersTemplate });
  },
  resetCollapsed(state) {
    Vue.set(state, 'collapsed', { ...collapsedTemplate });
  },
  sub(state, sub) {
    state.subs.push(sub);
  },
  setEstimatingStatuses(state, data) {
    Vue.set(state, 'estimatingStatuses', data);
  },
  setUnitOfMeasure(state, data) {
    Vue.set(state, 'unitsOfMeasure', data);
  },
  setFilter(state, { key, value }) {
    state.filters[key] = value;
  },
  setOpportunity(state, data) {
    Vue.set(state, 'opportunity', data);
  },
  setFacility(state, data) {
    Vue.set(state, 'facility', data);
  },
  setTemplate(state, data) {
    Vue.set(state, 'template', data);
  },
  setSOPRecord(state, { id, data }) {
    Vue.set(state.sops, id, data);
  },
  setSOPs(state, data) {
    Vue.set(state, 'sopMapping', data);
  },
  setRecord(state, data) {
    Vue.set(state, 'projectFinance', data);
  },
  setRecordDetail(state, { path, data }) {
    Vue.set(state.projectFinanceDetails, path, data);
  },
  setBulkRecordDetail(state, data) {
    Vue.set(state, 'projectFinanceDetails', data);
  },
  deleteRecordDetail(state, path) {
    Vue.delete(state.projectFinanceDetails, path);
  },
  selectVersion(state, { opportunityID, group, version }) {
    state.opportunityID = opportunityID;
    state.group = group;
    state.version = version;
  },
  toggleCollapsed(state, section) {
    state.collapsed[section] = !state.collapsed[section];
  },
  setShowChildrenInSearch(state, show) {
    state.showChildrenInSearch = show;
  },
  setShowOnlyNonEmpty(state, show) {
    state.showOnlyNonEmpty = show;
  },
  mergeDetails({ projectFinanceDetails }, detail) {
    if (projectFinanceDetails.hasOwnProperty(detail.path))
      Vue.set(projectFinanceDetails, detail.path, {
        ...projectFinanceDetails[detail.path],
        ...detail,
      });
    else Vue.set(projectFinanceDetails, detail.path, detail);
  },
  addLog({ projectFinanceDetails }, log) {
    if (projectFinanceDetails.hasOwnProperty('log')) projectFinanceDetails.log.items.push(log);
    else Vue.set(projectFinanceDetails, 'log', { items: [log] });
  },
  setRateTable(state, table) {
    state.rateTable = table;
  },
};

const actions = {
  initEstimatingStatus({ commit }) {
    getFirestorePicklist('estimatingStatus').then((elements) => commit('setEstimatingStatuses', elements));
  },

  initStore({ commit }, { opportunityID, group, version }) {
    commit('unsub');
    commit('resetCollapsed');
    commit('resetFilters');
    commit('clearTemplate');
    commit('clearRecord');
    commit('selectVersion', { opportunityID, group, version });
    const firestore = firebase.firestore();
    getFirestorePicklist('estimatingStatus').then((elements) => commit('setEstimatingStatuses', elements));
    getFirestorePicklist('unitofMeasure').then((elements) => commit('setUnitOfMeasure', elements));
    firestore
      .collection('opportunities')
      .doc(opportunityID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          swal(
            {
              title: 'Error!',
              text: 'Opportunity not found!',
              type: 'error',
              closeOnConfirm: true,
            },
            () => {
              router.push('/opportunities?where=pf');
            },
          );
        } else {
          commit('setOpportunity', doc.data());
          if (doc.get('facility')) {
            firestore
              .collection('facilities')
              .doc(doc.get('facility'))
              .get()
              .then((doc) => {
                if (doc.exists) {
                  commit('setFacility', doc.data());
                }
              });
          }
        }
      });

    const templateSubscription = firestore
      .collection('projectFinance')
      .doc('template')
      .onSnapshot((doc) => {
        if (doc.exists && !doc.metadata.hasPendingWrites) commit('setTemplate', doc.data());
      });
    commit('sub', templateSubscription);

    const sopMapperSubscription = firestore
      .collection('projectFinance')
      .doc('SOPs')
      .onSnapshot((doc) => {
        if (doc.exists) commit('setSOPs', doc.data());
      });
    commit('sub', sopMapperSubscription);

    const versionDoc = firestore
      .collection('projectFinance')
      .doc(opportunityID)
      .collection('groups')
      .doc(group)
      .collection('versions')
      .doc(version);
    const recordSub = versionDoc.onSnapshot((doc) => {
      if (!doc.exists) {
        swal(
          {
            title: 'Error!',
            text: 'Version not found!',
            type: 'error',
            closeOnConfirm: true,
          },
          () => {
            router.push({
              name: 'ProjectFinancePicker',
              params: { opportunityID },
            });
          },
        );
      } else commit('setRecord', doc.data());
    });
    commit('sub', recordSub);

    const sopsSub = firestore.collection('sops').onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        if (change.type !== 'removed') {
          commit('setSOPRecord', {
            id: change.doc.id,
            data: { ...data, id: change.doc.id },
          });
        }
      });
    });
    commit('sub', sopsSub);

    let projectFinanceDetailsFirstTime = true;
    const detailsSub = versionDoc.collection('projectFinanceDetails').onSnapshot((querySnapshot) => {
      const collectiveData = {};
      querySnapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        if (change.type === 'removed') {
          commit('deleteRecordDetail', data.path);
        } else {
          if (projectFinanceDetailsFirstTime) collectiveData[data.path] = data;
          else commit('setRecordDetail', { path: data.path, data });
        }
      });
      if (projectFinanceDetailsFirstTime) {
        projectFinanceDetailsFirstTime = false;
        commit('setBulkRecordDetail', collectiveData);
      }
    });
    commit('sub', detailsSub);

    const rateTableSub = firestore
      .collection('projectFinance')
      .doc(opportunityID)
      .collection('laborRates')
      .doc('estimationRates')
      .onSnapshot((doc) => {
        if (doc.exists) commit('setRateTable', doc.data());
      });
    commit('sub', rateTableSub);
  },
  saveProjectFinanceDetails({ state }, { path, obj }) {
    const firestore = firebase.firestore();
    const versionDoc = firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('groups')
      .doc(state.group)
      .collection('versions')
      .doc(state.version);
    const projectFinanceVersionDetailsCollection = versionDoc.collection('projectFinanceDetails');
    const isUpdate = state.projectFinanceDetails.hasOwnProperty(path);
    const logRef = projectFinanceVersionDetailsCollection.doc('__log');
    const docRef = isUpdate
      ? projectFinanceVersionDetailsCollection.doc(state.projectFinanceDetails[path].projectFinanceDetailID)
      : projectFinanceVersionDetailsCollection.doc();
    const batch = firestore.batch();

    const now = moment().toISOString(false);
    const record = {
      ...obj,
      path,
      projectFinanceDetailID: docRef.id,
      opportunityID: state.opportunityID,
      ...systemFields(isUpdate, firebase.auth().currentUser, now),
    };
    batch.set(docRef, record, { merge: true });

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: Object.entries(obj)
        .map(([key, value]) => {
          let changelogRecord, createdDate, modifiedDate, createdBy, modifiedBy;
          if (value === null) {
            changelogRecord = null;
          } else {
            ({
              createdDate,

              modifiedDate,

              createdBy,

              modifiedBy,
              ...changelogRecord
            } = value);
          }
          return `set ${key} to <code>${_escape(JSON.stringify(changelogRecord))}</code>`;
        })
        .join('<br>'),
    };
    batch.set(
      logRef,
      {
        [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
        path: 'log',
        projectFinanceDetailID: '__log',
      },
      { merge: true },
    );
    return batch.commit(); /*.then(() => {
      commit('mergeDetails', record);
      commit('addLog', logElement);
    })*/
  },
  saveProjectFinanceDetailsBreakup({ state }, { path, obj }) {
    const firestore = firebase.firestore();
    const versionDoc = firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('groups')
      .doc(state.group)
      .collection('versions')
      .doc(state.version);
    const projectFinanceVersionDetailsCollection = versionDoc.collection('projectFinanceDetails');
    const logRef = projectFinanceVersionDetailsCollection.doc('__log');

    const parentDocRef = projectFinanceVersionDetailsCollection.doc(state.projectFinanceDetails[path].projectFinanceDetailID);
    const batch = firestore.batch();

    const now = moment().toISOString(false);
    batch.delete(parentDocRef);

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: `Deleted record with note:<br>Breaking Up Estimation`,
    };
    batch.set(
      logRef,
      {
        [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
        path: 'log',
        projectFinanceDetailID: '__log',
      },
      { merge: true },
    );

    Object.entries(obj).forEach(([path, estimationObject]) => {
      const docRef = projectFinanceVersionDetailsCollection.doc();
      const record = {
        ...estimationObject,
        path,
        projectFinanceDetailID: docRef.id,
        opportunityID: state.opportunityID,
        ...systemFields(false, firebase.auth().currentUser, now),
      };
      batch.set(docRef, record, { merge: true });
      const logElement = {
        createdBy: firebase.auth().currentUser.displayName,
        created: now,
        changeLog: Object.entries(estimationObject)
          .map(([key, value]) => {
            let changelogRecord, createdDate, modifiedDate, createdBy, modifiedBy;
            if (value === null) {
              changelogRecord = null;
            } else {
              ({
                createdDate,

                modifiedDate,

                createdBy,

                modifiedBy,
                ...changelogRecord
              } = value);
            }
            return `set ${key} to <code>${_escape(JSON.stringify(changelogRecord))}</code>`;
          })
          .join('<br>'),
      };
      batch.set(
        logRef,
        {
          [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
          path: 'log',
          projectFinanceDetailID: '__log',
        },
        { merge: true },
      );
    });

    return batch.commit();

    // const isUpdate = state[recordDetailsName].hasOwnProperty(path);
    // const docRef = isUpdate
    //   ? projectFinanceVersionDetailsCollection.doc(state[recordDetailsName][path].projectFinanceDetailID)
    //   : projectFinanceVersionDetailsCollection.doc();
    // const batch = firestore.batch();
    //
    // const now = moment().toISOString(false);
    // const record = {
    //   ...obj,
    //   path,
    //   projectFinanceDetailID: docRef.id,
    //   opportunityID: state.opportunityID,
    //   ...systemFields(isUpdate, firebase.auth().currentUser, now),
    // };
    // batch.set(docRef, record, {merge: true});
    //
    // const logElement = {
    //   createdBy: firebase.auth().currentUser.displayName,
    //   created: now,
    //   changeLog: Object.entries(obj).map(([key, value]) => {
    //     let changelogRecord, createdDate, modifiedDate, createdBy, modifiedBy;
    //     if(value === null) {
    //       changelogRecord = null;
    //     } else {
    //       ({createdDate, modifiedDate, createdBy, modifiedBy, ...changelogRecord} = value);
    //     }
    //     return `set ${key} to <code>${_escape(JSON.stringify(changelogRecord))}</code>`
    //   }).join('<br>'),
    // };
    // batch.set(logRef, {
    //   [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
    //   path: "log",
    //   projectFinanceDetailID: "__log",
    // }, {merge: true})
    // return batch.commit()/*.then(() => {
    //   commit('mergeDetails', record);
    //   commit('addLog', logElement);
    // })*/;
  },
  deleteProjectFinanceDetails({ state }, { path, note }) {
    const firestore = firebase.firestore();
    const versionDoc = firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('groups')
      .doc(state.group)
      .collection('versions')
      .doc(state.version);
    const projectFinanceVersionDetailsCollection = versionDoc.collection('projectFinanceDetails');
    const logRef = projectFinanceVersionDetailsCollection.doc('__log');
    const docRef = projectFinanceVersionDetailsCollection.doc(state.projectFinanceDetails[path].projectFinanceDetailID);
    const batch = firestore.batch();

    const now = moment().toISOString(false);
    batch.delete(docRef);

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: `Deleted record with note:<br>${note}`,
    };
    batch.set(
      logRef,
      {
        [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
        path: 'log',
        projectFinanceDetailID: '__log',
      },
      { merge: true },
    );
    return batch.commit();
  },
  setSop(_, { path, sop }) {
    const firestore = firebase.firestore();
    return firestore
      .collection('projectFinance')
      .doc('SOPs')
      .set(
        {
          [path]: sop,
        },
        { merge: true },
      );
  },
  setRateTableItem({ state }, { code, item }) {
    const firestore = firebase.firestore();

    const now = moment().toISOString(false);
    const record = {
      ...item,
      code,
      ...systemFields(state.rateTable?.items?.[code], firebase.auth().currentUser, now),
    };

    const {
      createdDate,

      modifiedDate,

      createdBy,

      modifiedBy,
      ...changelogRecord
    } = item;

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: Object.entries(changelogRecord)
        .map(([key, value]) => {
          return `set ${key} to <code>${_escape(JSON.stringify(value))}</code>`;
        })
        .join('<br>'),
    };

    return firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('laborRates')
      .doc('estimationRates')
      .set(
        {
          items: {
            [code]: record,
          },
          log: firebase.firestore.FieldValue.arrayUnion(logElement),
          opportunityID: state.opportunityID,
        },
        { merge: true },
      );
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
