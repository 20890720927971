import { required } from 'vuelidate/lib/validators';

export const validations = {
  form: {
    // Record ID
    id: {},
    recordNumber: {},

    // Fields on layout
    name: { required },
    type: { required },
    versionCount: {},

    // System Fields
    createdBy: {},
    createdDate: {},
    modifiedBy: {},
    modifiedDate: {},
  },
  versionForm: {
    id: {},
    // Status & Version Number
    status: { required },
    approver: { required },
    recordNumber: {},

    // Procedure
    procedure: { required },
    interestedGroups: {
      $each: { required },
    },
    comments: {},
    pictures: {},
    embedURL: {},

    // Version Record System Fields
    createdBy: {},
    createdDate: {},
    modifiedBy: {},
    modifiedDate: {},
  },
};
