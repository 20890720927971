import firebase from 'firebase';
import Contact from '@/core/entities/Contact';
import ContactsRepository from '@/core/features/contacts/interfaces/ContactsRepository';

export default class ContactsRepositoryFirestoreImpl implements ContactsRepository {
  private collectionName = 'contacts';
  getContact({ id }: { id: string }): Promise<Contact> {
    const firestore = firebase.firestore();
    const document = firestore.collection(this.collectionName).doc(id);
    return document
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error('Contact does not exist');
        }

        return {
          id: doc.id,
          ...doc.data(),
        } as any;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  getSubordinates({ contactId }: { contactId: string }): Promise<Contact[]> {
    const firestore = firebase.firestore();
    const collection = firestore.collection(this.collectionName);
    const queryRef = collection.where('manager', '==', contactId);
    return queryRef
      .get()
      .then((querySnapshot) => {
        const contacts: Contact[] = [];
        querySnapshot.forEach((doc) => {
          contacts.push({
            id: doc.id,
            ...doc.data(),
          } as any);
        });
        return contacts;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }
}
