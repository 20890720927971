import { getAllRecords, querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  returns: [],
  subscription: null,
};

export const getters = {
  returns: ({ returns }) => returns,
  returnRecord: (state) => (id) => state.returns.find((returnRecord) => returnRecord.id === id),
};

export const mutations = {
  SET_RETURNS(state, returns) {
    state.returns = returns;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchReturns({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('returns');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_RETURNS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveReturnRecord(context, returnOptions) {
    return saveRecord(returnOptions).then((id) => id);
  },
  fetchReturnRecord({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.returnRecord(id)) {
        resolve(getters.returnRecord(id));
        return;
      }

      const returnDocument = firestoreDocument('returns')(id);
      returnDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
