import { Globals, InstallerRole, JVPartnerRole, LightingReviewerRole, PATHInstallerRole } from '@/config/variables';
import { deleteImages, pictureUpload } from '@/services/firebase/StorageService.js';
import { firestoreDocument } from '@/services/utils/Utils';
import axios from 'axios';
import firebase from 'firebase';

const sortFunction = (a, b) => {
  const nameA = a.displayName ? a.displayName.toLowerCase() : a.displayName;
  const nameB = b.displayName ? b.displayName.toLowerCase() : b.displayName;
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const namespaced = true;

export const state = {
  users: [],
  defaultURL:
    'https://firebasestorage.googleapis.com/v0/b/lightedison-devserver.appspot.com/o/0.png?alt=media&token=ae12ac21-5b38-4293-847e-95b67889a30f',
};

export const getters = {
  users: ({ users }) => users.map(({ uid, ...user }) => ({ ...user, id: uid })),
  user:
    ({ users }) =>
    (userId) =>
      users.find(({ id }) => id == userId),
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
};

export const actions = {
  saveUser({ state }, { uid, picture, data, assignedOpportunities = [] }) {
    const { email, password, displayName, phoneNumber, userRole, newRole, contactRecord } = data;
    if ([email, password, displayName, phoneNumber].includes(false)) {
      throw new Error('Email, Password, Display Name and Phone Number are required');
    }

    let photoURL = data.photoURL || state.defaultURL;
    const auth = firebase.auth();
    const url = `${Globals.fireBaseUrl}${uid ? 'updateUser' : 'addNewUser'}`;

    return new Promise(async (resolve, reject) => {
      try {
        // Checking if user uploaded a photo
        if (picture) {
          if (photoURL && photoURL !== state.defaultURL) {
            await deleteImages([{ url: photoURL }]);
          }

          const [uploadResult] = await pictureUpload([picture], 'picture_uploads/users', deleteImages);
          photoURL = uploadResult.url;
        }

        const user = {
          email,
          ...(uid ? { uid } : { password }),
          displayName,
          phoneNumber,
          photoURL,
          userRole,
          newRole,
          contactRecord,
        };

        // Saving User
        const savedUser = await auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then((idToken) => axios.post(url, { token: idToken, user }))
          .then(
            (response) => response.data,
            (error) => {
              throw new Error(error.response.data.msg);
            },
          );

        // Saving assigned opportunities
        if ([InstallerRole, PATHInstallerRole, LightingReviewerRole, JVPartnerRole].includes(userRole)) {
          const userId = savedUser.data.uid;
          const installerOpportunitiesDocument = firestoreDocument('installerOpportunities')(userId);
          await installerOpportunitiesDocument.set({
            opptyIDs: assignedOpportunities,
          });
        }

        resolve(savedUser);
      } catch (error) {
        return reject(error);
      }
    });
  },
  deleteUser({ state }, { id: uid, photoURL }) {
    photoURL = photoURL || state.defaultURL;
    if (!uid) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      if (photoURL && photoURL !== state.defaultURL) {
        await deleteImages([{ url: photoURL }]);
      }

      const auth = firebase.auth();
      const url = `${Globals.fireBaseUrl}${'deleteUser'}`;

      await auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then((idToken) => axios.post(url, { token: idToken, uid }))
        .then(
          (response) => response.data,
          (error) => {
            reject(error.response.data.msg);
          },
        );

      // Deleting assigned opportunities if existent
      try {
        const opportunitiesInstallerDocument = firestoreDocument('installerOpportunities')(uid);
        await opportunitiesInstallerDocument.delete();
      } catch (error) {
        console.log("The user doesn't have an installerOpportunities document");
        console.error(error);
      }
      resolve();
    });
  },
  fetchUsers({ commit }) {
    const auth = firebase.auth();
    const url = Globals.fireBaseUrl + 'listAllUsers';
    return auth.currentUser
      .getIdToken(/* forceRefresh */ true)
      .then((idToken) => axios.post(url, { token: idToken }))
      .then(({ data }) => [...data].sort(sortFunction))
      .then((users) => {
        commit('SET_USERS', users);
      })
      .catch((error) => error);
  },
  fetchUser(_, id) {
    return new Promise((resolve, reject) => {
      const auth = firebase.auth();
      const url = Globals.fireBaseUrl + 'getUserByUID';
      auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(() => axios.post(url, { uid: id }))
        .then(({ data }) => {
          const { uid, ...user } = data;
          resolve({ ...user, id: uid });
        })
        .catch((error) => reject(error));
    });
  },
};
